import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from "react";

import EventBus from 'eventing-bus';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import NFTData from '../../components/nftsdata';
import { useContract, useContractRead, useSetIsWalletModalOpen } from "@thirdweb-dev/react";
import { getMetadata, setCartItems } from "../../store/actions/NFT";
import { toggleLoader, toggleReviewCartModal, toggleBuyNFTModal, setisCartNFTs } from "../../store/actions/Auth";

import 'animate.css';
import './index.css';
import "react-toastify/dist/ReactToastify.css";

const NftTradingCard = () => {
    const dispatch = useDispatch();
    const setIsWalletModalOpen = useSetIsWalletModalOpen();
    const [sticky, setSticky] = useState(false);

    let nftId = window.location.href.split('#')[1];
    let { auth, publicAddress } = useSelector(st => st.Auth);
    let { TokenAddress, TokenABI, cartItems, nftMetadata, currentSalePrice, saleStage } = useSelector(st => st.NFT);

    const { contract: tokenContract } = useContract(TokenAddress, TokenABI);
    const { data: tokenBalance } = useContractRead(tokenContract, 'balanceOf', [publicAddress, nftId]);

    let [tableStats, setTableStats] = useState({});
    let [state, setState] = useState({ nav1: null, nav2: null });

    let slider1 = useRef();
    let slider2 = useRef();
    const settingCarousel = {};
    const { nav1, nav2 } = state;
    const settingsSliderNav = { slidesToShow: 3, slidesToScroll: 1, dots: false, arrows: false, focusOnSelect: true, loop: false };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (nftId) {
            dispatch(toggleLoader(true));
            dispatch(getMetadata({ nftId }));
        }
    }, []);


    const onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) setSticky(true);
        if (pageYOffset < 20) setSticky(false);
    };


    useEffect(() => {
        setState({ nav1: slider1.current, nav2: slider2.current });
        dispatch(toggleLoader(true));

        let images = document.getElementsByTagName('img');
        let loaded = images.length;
        for (var i = 0; i < images.length; i++) {
            if (images[i].complete) loaded--;
            else {
                images[i].addEventListener('load', () => {
                    loaded--;
                    if (loaded === 0) setTimeout(() => dispatch(toggleLoader(false)), 1000);
                });
            }
            if (loaded === 0) setTimeout(() => dispatch(toggleLoader(false)), 1000);
        }
    }, []);

    useEffect(() => {
        if (nftId < 0 || nftId > 10) window.location.replace("/");
    }, [nftId]);


    useEffect(() => {
        let video = document.getElementById('video');
        if (video) setTimeout(() => video.muted = false, 500);

        let tableStats = {
            allowMinted: nftMetadata?.['allowlistSaleMintAmount'],
            publicMinted: nftMetadata?.['publicSaleMintAmount'],
        };
        setTableStats(tableStats);
    }, [nftMetadata]);

    const nftPurchasingValidation = () => {
        let isValid = true;
        let errorMessage = '';

        if (saleStage === "Sale not started yet" || saleStage === "Private Sale") {
            isValid = false;
            errorMessage = "Allowlist Sale not started yet";
        } else if ((Number(nftMetadata['tokenMinted']) + 21) >= 420) {
            isValid = false;
            errorMessage = `${nftMetadata?.['name']} is sold out`;
        }

        return { isValid, errorMessage };
    };

    const handleAddToCart = async () => {
        const { isValid, errorMessage } = nftPurchasingValidation();
        if (!isValid) { EventBus.publish("info", errorMessage); return; }

        if (cartItems && Object.keys(cartItems).length > 0) {
            if (cartItems[nftMetadata?.['name']]) cartItems = { ...cartItems, [nftMetadata?.['name']]: { ...nftMetadata, nftId, quantity: Number(cartItems[nftMetadata?.['name']]?.['quantity']) + 1 } };
            else cartItems = { ...cartItems, [nftMetadata?.['name']]: { ...nftMetadata, nftId, quantity: 1 } };
            dispatch(setCartItems(cartItems));
        }
        else {
            let cart = {};
            if (nftMetadata && !cart[nftMetadata?.['name']]) cart[nftMetadata?.['name']] = { ...nftMetadata, nftId, quantity: 1 };
            dispatch(setCartItems(cart));
        }
        dispatch(toggleReviewCartModal(true));
    };

    const buyButtonValidations = async () => {
        const { isValid, errorMessage } = nftPurchasingValidation();
        if (!isValid) { EventBus.publish("info", errorMessage); return; }

        if (!auth) setIsWalletModalOpen(true);
        else {
            dispatch(setisCartNFTs(false));
            dispatch(toggleBuyNFTModal(true))
        }
    };

    return (
        <div className='cheeba-nft-collection-page' onWheel={onScroll}>
            <div>
                <Navbar sticky={sticky} />
                <section className='product-detail-sec'>
                    <div className="auto-container">
                        <div className='row'>
                            <div className='col-xl-5 col-lg-6 col-md-12 col-sm-12'>
                                <div className="larg-slider">
                                    <Slider
                                        asNavFor={nav2}
                                        ref={slider => (slider1.current = slider)}
                                        {...settingCarousel}>
                                        <div>
                                            <div className="item">
                                                <div className="about-game-img">
                                                    {nftMetadata?.animation_url &&
                                                        <video id="video" controls loop muted autoPlay>
                                                            <source src={nftMetadata?.animation_url} type="video/mp4" />
                                                        </video>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="item">
                                                <div className="about-game-img">
                                                    {nftMetadata?.['front'] && <img src={nftMetadata['front']} alt='' />}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="item">
                                                <div className="about-game-img">
                                                    {nftMetadata?.['back'] && <img src={nftMetadata['back']} alt='' />}
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                                <Slider className="slider-nav-thumbnails"
                                    asNavFor={nav1}
                                    ref={slider => (slider2.current = slider)}
                                    {...settingsSliderNav}>
                                    <div>
                                        <div className="item-thumbnail">
                                            <div className="about-game-img">
                                                {nftMetadata?.['front'] && <img src={nftMetadata['front']} alt='' />}
                                                <div className="overlay-box">
                                                    <button className="video-icon"><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-thumbnail">
                                            <div className="about-game-img">
                                                {nftMetadata?.['front'] && <img src={nftMetadata['front']} alt='' />}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-thumbnail">
                                            <div className="about-game-img">
                                                {nftMetadata?.['back'] && <img src={nftMetadata['back']} alt='' />}
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                            <div className="col-xl-7 col-lg-6 col-md-12">
                                <div className="right-content">
                                    {nftMetadata?.['name'] && <h2>{`CheebaPet Trading Card ${nftMetadata['name']} #${nftId} - ${nftId < 11 ? 'Vaulted' : 'Shipped'}`}</h2>}
                                    {nftMetadata?.['tokenMinted'] && <p>{Number(nftMetadata['tokenMinted']) + 21} of 420 Total Supply</p>}
                                    {tokenBalance && <p>{`${tokenBalance.toString()} owned by `}<Link to="/profile">you</Link></p>}
                                    <div className="price-area">
                                        <p>Current Price:</p>
                                        {currentSalePrice && <h2>{`${currentSalePrice} ETH`}</h2>}
                                    </div>
                                    <div className="table-area">
                                        <h3>Mint Schedule</h3>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Stage</th>
                                                        <th scope="col">%</th>
                                                        <th scope="col">Minted</th>
                                                        <th scope="col">Available</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>April 10</td>
                                                        <td>Creators</td>
                                                        <td>5%</td>
                                                        <td>21</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>April 20</td>
                                                        <td>Public Mint</td>
                                                        <td>95%</td>
                                                        <td>{(Number(tableStats['publicMinted'])) || 0}</td>
                                                        <td>{399 - Number(tableStats['publicMinted'])}</td>
                                                    </tr>
                                                    <tr className="background-table">
                                                        <td></td>
                                                        <td></td>
                                                        <td>100%</td>
                                                        <td>{Number(nftMetadata?.['tokenMinted']) + 21}/420</td>
                                                        {(tableStats['publicMinted']) &&
                                                            <td>{(399 - Number(tableStats['publicMinted']))}/420</td>
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="btn-area text-center">
                                        <button className="btn-style-one" onClick={() => buyButtonValidations()}>Buy Now</button>
                                        <br />
                                        <button className="btn-style-two" onClick={() => handleAddToCart()}>Add to Cart</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
                                <div className="bottom-slider">
                                    <div id="accordion">
                                        <div class="card">
                                            <div class="card-header" id="headingOne">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Traits
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseOne" class="collapse collapase-active-area show" aria-labelledby="headingOne" data-parent="#accordion">
                                                <div class="card-body">
                                                    <div className="traits-column">
                                                        {nftMetadata?.['attributes'] && nftMetadata?.['attributes'].length > 0
                                                            ? <>{nftMetadata?.['attributes'].map(item => {
                                                                return (
                                                                    <div className="traits-box">
                                                                        <p>{item['trait_type']}</p>
                                                                        <h4>{item['value'].replaceAll(',', ', ')}</h4>
                                                                    </div>
                                                                )
                                                            })}</>
                                                            : <h5 className="ml-4">No Traits Found!</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Details
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseTwo" class="collapse collapase-active-area" aria-labelledby="headingTwo" data-parent="#accordion">
                                                <div class="card-body">
                                                    <div className="detial-box">
                                                        <ul>
                                                            <li>Contact Address <span>{TokenAddress && TokenAddress.substring(0, 5) + '.....' + TokenAddress.substring(37, TokenAddress.length)}</span></li>
                                                            <li>Token ID <span>{nftId}</span></li>
                                                            <li>Token Starndard <span>ERC1155</span></li>
                                                            <li>Chain <span className="style-two">Ethereum</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="headingThree">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        About CheebaPet
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseThree" class="collapse collapase-active-area" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div class="card-body">
                                                    <div className="detial-box">
                                                        <p>{nftMetadata?.['description'] || '-'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='tranding-sec' id='nft-marketplace'>
                    <div className="auto-container">
                        <div className='row'>
                            <div className='col-12'>
                                <div className='sec-title'>
                                    <h2>More From This Collection</h2>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='tranding-cards'>
                                    <NFTData page="product" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div >
    );
}

export default NftTradingCard;