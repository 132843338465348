import WOW from 'wowjs';
import Countdown from 'react-countdown';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import { toggleLoader } from "../../store/actions/Auth";

import 'animate.css';
import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import NFTData from '../../components/nftsdata';
import Allowlist from '../../components/allowlist';
import IrlPlanterProducts from '../../components/IrlPlanterCards';
import NftPlanters from '../../components/NftPlanters';
import { counterReload } from "../../store/actions/NFT";


const Home = () => {
    const dispatch = useDispatch();

    const { nextSaleStartTime, saleStage, irlPlantersJSON, irlPlanterTime } = useSelector(st => st.NFT);
    const nextSaleStage = saleStage === 'Private Sale' ? 'Allowlist' : saleStage === 'Allowlist Sale' ? 'Public' : '';

    const [sticky, setSticky] = useState(false);
    const [isOGCounter, setIsOGCounter] = useState(false);
    const [activeSection, setActiveSection] = useState('');
    const [isPlanterCounter, setIsPlanterCounter] = useState(false);
    const [isIrlPlanterCounter, setIsIrlPlanterCounter] = useState(false);

    useEffect(() => {
        dispatch(toggleLoader(true));

        new WOW.WOW({ live: true }).init();
        let images = document.getElementsByTagName('img');
        let loaded = images.length;
        for (var i = 0; i < images.length; i++) {
            if (images[i].complete) {
                loaded--;
            } else {
                images[i].addEventListener('load', () => {
                    loaded--;
                    if (loaded === 0) setTimeout(() => dispatch(toggleLoader(false)), 1000);
                });
            }
            if (loaded === 0) setTimeout(() => dispatch(toggleLoader(false)), 1000);
        }
    }, []);


    const onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) setSticky(true);
        if (pageYOffset < 20) setSticky(false);
    };

    const setVisible = (active) => {
        if (activeSection === active) setActiveSection('');
        else setActiveSection(active);
    };

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <h2>NFT Sale Live</h2>;
        }
        else {
            return (
                <div className="countdown-timer-time">
                    <div className="counter-day counter-days">
                        <h5>{days ? days : '-'}</h5>
                        <h5>D</h5>
                    </div>
                    :
                    <div className="counter-day counter-hour">
                        <h5>{hours ? hours : '-'}</h5>
                        <h5>H</h5>
                    </div>
                    :
                    <div className="counter-day counter-minute">
                        <h5>{minutes ? minutes : '-'}</h5>
                        <h5>M</h5>
                    </div>
                    :
                    <div className="counter-day counter-seconds">
                        <h5>{seconds ? seconds : '-'}</h5>
                        <h5>S</h5>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className='cheeba-nft-collection-page' onWheel={onScroll}>
            <div>
                <Navbar sticky={sticky} activeSection={activeSection} />
                {/* Banner Section */}
                <VisibilitySensor onChange={() => setVisible('Banner')}>
                    <section className='banner-sec' id='home'>
                        <div className="auto-container">
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='inner-container'>
                                        <div className='row'>
                                            <div className='col-lg-8 col-md-12'>
                                                <div className='text-box-banner'>
                                                    <h1><img src={require('../../static/images/heading-banner.png')} alt='' /></h1>
                                                    <h2>CheebaPet Dual Dimension NFTs</h2>
                                                    <h3>Pairing NFTs with In-Real-Life (IRL) Products</h3>
                                                    {/* <Link className='banner-btn' to="#">Allowlist Request</Link> */}
                                                </div>
                                            </div>
                                            <div className='df col-lg-4 col-md-12 img-box-banner'>
                                                <video id="bannerVideo" autoPlay loop muted>
                                                    <source src="https://nft-trading-cards.s3.us-east-2.amazonaws.com/1.mp4" type="video/mp4" />
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>


                <section className='planet-sec' id='planet'>
                    <div className="auto-container">
                        <div className='row'>
                            <div className='col-12'>
                                <div className='sec-title green-title text-center'>
                                    <h2>SPECIAL PROMOTION <br />Purchase an OG Trading Card and Receive a Free IRL CheebaPet Planter Kit</h2>
                                    <h3>Limited Edition Alpha Collection Planter Kits</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* NFT Marketplace */}
                <VisibilitySensor onChange={() => setVisible('NFT Marketplace')}>
                    <section className='tranding-sec tranding-black-sec' id='nft-marketplace'>
                        <div className="auto-container">
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='sec-title'>
                                        <h2>CheebaPet NFT Trading Cards - OG Collection</h2>
                                        <h3>Access Pass for Everything CheebaPet<br />Receive 1 Free Alpha Collection IRL Planter Kit for Each OG Trading Card Purchased</h3>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    {saleStage !== 'Sale not started yet' &&
                                        <div className='counter-box mb-2'>
                                            <div className='counter-box-item'><h4>Current Sale Stage:</h4></div>
                                            <div className='counterinner'><h2>{saleStage}</h2></div>
                                        </div>}
                                    {nextSaleStartTime > 0 &&
                                        <div className='counter-box'>
                                            <div className='counter-box-item'>
                                                <h4>{`${nextSaleStage} Sale Begins`}</h4>
                                            </div>
                                            <div className='counterinner'>
                                                <i className='icon'><img src={require('../../static/images/time-icon.png')} alt='' /></i>
                                                <Countdown date={Date.now() + parseInt((Number(nextSaleStartTime) * 1000) - Date.now())} renderer={renderer} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12'>
                                    <div className='tranding-cards'>
                                        <div className='row'>
                                            <NFTData page="home" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>

                {/* IRL */}
                <VisibilitySensor onChange={() => setVisible('Planet')}>
                    <section className='planet-sec' id='planet'>
                        <div className="auto-container">
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='sec-title'>
                                        <h2>CheebaPet IRL Planters Kits – Alpha Collection</h2>
                                        {/* <h3>Complete Grow Kits, Just Add Water<br />Only available June 1 thru June 30<br />Purchase an OG Trading Card to Receive 1 Free IRL Planter Kit per Card,<br />and 20% Discount on Purchases of Additional Planter Kits</h3> */}
                                        <h3>Complete Grow Kits, Just Add Water<br />Purchase an OG Trading Card to Receive 1 Free IRL Planter Kit per Card</h3>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='counter-box'>
                                        <h4>6/01 Drop Date:</h4>
                                        <div className='counterinner'>
                                            {isIrlPlanterCounter
                                                ? <h2>IRL Planters Sale Live</h2>
                                                : <>
                                                    <i className='icon'><img src={require('../../static/images/time-icon.png')} alt='' /></i>
                                                    {irlPlanterTime > 0 &&
                                                        <Countdown date={Date.now() + parseInt((irlPlanterTime * 1000) - Date.now())} renderer={renderer} onComplete={() => setIsIrlPlanterCounter(true)} />
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <IrlPlanterProducts page="home" />
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Planters */}
                <VisibilitySensor onChange={() => setVisible('Planet')}>
                    <section className='planet-sec tranding-black-sec' id='planet'>
                        <div className="auto-container">
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='sec-title'>
                                        <h2>CheebaPet NFT Planters – Alpha Collection</h2>
                                        <h3>Purchase an IRL CheebaPet Planter Kit and Claim a Free NFT Planter</h3>
                                        {/* <h3>Purchase an IRL CheebaPet Planter Kit and Claim a Free NFT Planter<br />NFT Planters will Unlock Exclusive Benefits in Cheeba.World on<br />10/10 Happiness to Humans Day </h3> */}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='counter-box'>
                                        <h4>Drop Date:</h4>
                                        <div className='counterinner'>
                                            {isPlanterCounter
                                                ? <h2>Sale Not Live Yet</h2>
                                                : <>
                                                    <i className='icon'><img src={require('../../static/images/time-icon.png')} alt='' /></i>
                                                    <Countdown date={Date.now() + parseInt((1719789600 * 1000) - Date.now())} renderer={renderer} onComplete={() => setIsPlanterCounter(true)} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-12'>
                                    <div className='nft-box'>
                                        <div className='overlaybox'>
                                            <h2>CheebaPet 3D NFT Planters</h2>
                                            <h3>Paired with IRL Matching Planter Kits</h3>
                                        </div>
                                        <img src={require('../../static/images/nft-plantes.png')} alt='' />
                                    </div>
                                </div> */}

                            </div>
                            <NftPlanters />
                        </div>
                    </section>
                </VisibilitySensor>


                {/* Planet */}
                {/* <VisibilitySensor onChange={() => setVisible('Planet')}>
                    <section className='planet-sec' id='planet'>
                        <div className="auto-container">
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='sec-title'>
                                        <h2>CheebaPet NFT Planters</h2>
                                        <h3>Digital Meets Physical, the World’s First Living NFT<br />Collect Digital Planters and Grow IRL Plants</h3>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='counter-box'>
                                        <h4>6/01 Drop Date:</h4>
                                        <div className='counterinner'>
                                            {isPlanterCounter
                                                ? <h2>NFT Planters Sale Live</h2>
                                                : <>
                                                    <i className='icon'><img src={require('../../static/images/time-icon.png')} alt='' /></i>
                                                    <Countdown date={Date.now() + parseInt((1717197600 * 1000) - Date.now())} renderer={renderer} onComplete={() => setIsPlanterCounter(true)} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col">
                                    <div className='tranding-box'>
                                        <div className='flip-box'>
                                            <div class="flip-box-front">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-1.png')} alt='' />
                                                </div>
                                            </div>
                                            <div class="flip-box-back">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-1.png')} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>Zkittlez #1</h4>
                                            <div className='price-area'>
                                                <p>Price: <b>0.03 ETH</b></p>
                                                <button className='buy-btn' href="#">Buy</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className='tranding-box'>
                                        <div className='flip-box'>
                                            <div class="flip-box-front">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-2.png')} alt='' />
                                                </div>
                                            </div>
                                            <div class="flip-box-back">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-2.png')} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>WhiteWidow #2</h4>
                                            <div className='price-area'>
                                                <p>Price: <b>0.03 ETH</b></p>
                                                <button className='buy-btn' href="#">Buy</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className='tranding-box'>
                                        <div className='flip-box'>
                                            <div class="flip-box-front">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-3.png')} alt='' />
                                                </div>
                                            </div>
                                            <div class="flip-box-back">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-3.png')} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>TrainWreck #3</h4>
                                            <div className='price-area'>
                                                <p>Price: <b>0.03 ETH</b></p>
                                                <button className='buy-btn' href="#">Buy</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className='tranding-box'>
                                        <div className='flip-box'>
                                            <div class="flip-box-front">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-4.png')} alt='' />
                                                </div>
                                            </div>
                                            <div class="flip-box-back">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-4.png')} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>SweetTooth #4</h4>
                                            <div className='price-area'>
                                                <p>Price: <b>0.03 ETH</b></p>
                                                <button className='buy-btn' href="#">Buy</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className='tranding-box'>
                                        <div className='flip-box'>
                                            <div class="flip-box-front">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-5.png')} alt='' />
                                                </div>
                                            </div>
                                            <div class="flip-box-back">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-5.png')} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>SuperSkunk #5</h4>
                                            <div className='price-area'>
                                                <p>Price: <b>0.03 ETH</b></p>
                                                <button className='buy-btn' href="#">Buy</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className='tranding-box'>
                                        <div className='flip-box'>
                                            <div class="flip-box-front">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-6.png')} alt='' />
                                                </div>
                                            </div>
                                            <div class="flip-box-back">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-6.png')} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>SourDiesel #6</h4>
                                            <div className='price-area'>
                                                <p>Price: <b>0.03 ETH</b></p>
                                                <button className='buy-btn' href="#">Buy</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className='tranding-box'>
                                        <div className='flip-box'>
                                            <div class="flip-box-front">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-7.png')} alt='' />
                                                </div>
                                            </div>
                                            <div class="flip-box-back">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-7.png')} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>PurpleHaze #7</h4>
                                            <div className='price-area'>
                                                <p>Price: <b>0.03 ETH</b></p>
                                                <button className='buy-btn' href="#">Buy</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className='tranding-box'>
                                        <div className='flip-box'>
                                            <div class="flip-box-front">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-8.png')} alt='' />
                                                </div>
                                            </div>
                                            <div class="flip-box-back">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-8.png')} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>NorthernLights #8</h4>
                                            <div className='price-area'>
                                                <p>Price: <b>0.03 ETH</b></p>
                                                <button className='buy-btn' href="#">Buy</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className='tranding-box'>
                                        <div className='flip-box'>
                                            <div class="flip-box-front">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-9.png')} alt='' />
                                                </div>
                                            </div>
                                            <div class="flip-box-back">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-9.png')} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>GorillaGlue #9</h4>
                                            <div className='price-area'>
                                                <p>Price: <b>0.03 ETH</b></p>
                                                <button className='buy-btn' href="#">Buy</button>
                                            </div>
                                            
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className='tranding-box'>
                                        <div className='flip-box'>
                                            <div class="flip-box-front">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-10.png')} alt='' />
                                                </div>
                                            </div>
                                            <div class="flip-box-back">
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/mystery-box-10.png')} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>BruceBanner #10</h4>
                                            <div className='price-area'>
                                                <p>Price: <b>0.03 ETH</b></p>
                                                <button className='buy-btn' href="#">Buy</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor> */}


                {/* NFT */}
                {/* <VisibilitySensor onChange={() => setVisible('NFT')}>
                    <section className='planet-sec' id='nftcheebad'>
                        <div className="auto-container">
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='sec-title'>
                                        <h2>CheebaPet NFT PFPs – Genesis Collection</h2>
                                        <h3>Total Collection 10,000:  10 x 1000 Unique Variations<br />Reveal After Mint</h3>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='counter-box'>
                                        <h4>11/11 Drop Date:</h4>
                                        <div className='counterinner'>
                                            {isOGCounter
                                                ? <h2>OG Collection Sale Live</h2>
                                                : <>
                                                    <i className='icon'><img src={require('../../static/images/time-icon.png')} alt='' /></i>
                                                    <Countdown date={Date.now() + parseInt((1731331200 * 1000) - Date.now())} renderer={renderer} onComplete={() => setIsOGCounter(true)} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='nft-box'>
                                        <div className='overlaybox'>
                                            <h2>CheebaPet NFT PFPs</h2>
                                            <h3>10K Genesis Collection</h3>
                                        </div>
                                        <img src={require('../../static/images/nft-plantes-1.png')} alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor> */}


                {/* Allow List */}
                {/* <VisibilitySensor onChange={() => setVisible('Allow List')}>
                    <Allowlist />
                </VisibilitySensor> */}
            </div>
            <Footer />
        </div >
    );
}

export default Home;