import { useDispatch, useSelector } from 'react-redux';
import Countdown from 'react-countdown';
import React, { useState, useEffect, useRef } from "react";

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import IrlPlanterProducts from '../../components/IrlPlanterCards';
import NftPlanters from '../../components/NftPlanters';

const Planters = () => {
    const [sticky, setSticky] = useState(false);
    const [isPlanterCounter, setIsPlanterCounter] = useState(false);
    const [isIrlPlanterCounter, setIsIrlPlanterCounter] = useState(false);

    const { irlPlanterTime } = useSelector(st => st.NFT);


    const onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) setSticky(true);
        if (pageYOffset < 20) setSticky(false);
    };


    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <h2>NFT Sale Live</h2>;
        }
        else {
            return (
                <div className="countdown-timer-time">
                    <div className="counter-day counter-days">
                        <h5>{days ? days : '-'}</h5>
                        <h5>D</h5>
                    </div>
                    :
                    <div className="counter-day counter-hour">
                        <h5>{hours ? hours : '-'}</h5>
                        <h5>H</h5>
                    </div>
                    :
                    <div className="counter-day counter-minute">
                        <h5>{minutes ? minutes : '-'}</h5>
                        <h5>M</h5>
                    </div>
                    :
                    <div className="counter-day counter-seconds">
                        <h5>{seconds ? seconds : '-'}</h5>
                        <h5>S</h5>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className='cheeba-nft-collection-page' onWheel={onScroll}>
            <div>
                <Navbar sticky={sticky} />
                <section className='banner-sec style-two planter-page'>
                    <div className="auto-container">
                        <div className='row'>
                            <div className='col-12'>
                                <div className='inner-container' style={{ padding: '25px 0' }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <div className="title-area">
                                                <h2>CheebaPet In-Real-Life (IRL) Planter Kits</h2>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='nft-box-banner center-box'>
                                                <h3>Everything You Need to<br /> Grow Select CheebaPet Strains<br />  In the Comfort of Your Own Home</h3>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='nft-box-banner center-box planter-box-nft'>
                                                <div className="img-box">
                                                    <img src={require('../../static/images/planter-banner-img.png')} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='nft-box-banner right-list-box'>
                                                <h3>Each CheebaPet Planter Kit Includes:</h3>
                                                <ul>
                                                    <li><i className='icon'><img src={require('../../static/images/check-icon.png')} alt='' /></i>Characterized Planter</li>
                                                    <li><i className='icon'><img src={require('../../static/images/check-icon.png')} alt='' /></i>NutraSoil</li>
                                                    <li><i className='icon'><img src={require('../../static/images/check-icon.png')} alt='' /></i>Auto-Flowering Seeds</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className='planet-sec cheeba-planter-page'>
                    <div className="auto-container">
                        <div className='row'>
                            <div className='col-12'>
                                <div className='sec-title green-title text-center'>
                                    <h2>SPECIAL PROMOTION <br />Alpha Collection IRL Planter Kits<br />Limited Edition Only Available June 1 – June 30</h2>
                                    <h3>Get Your Seeds in the Soil by 7/10 and <br />Harvest by 10/10 Happiness to Humans Day to Partake in a Special Celebration</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section className='benifits-sec planter-page-benifits'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className="col-12">
                                <div className="benifits-inner">
                                    <div className="title-area">
                                        <h2>CheebaPet In-Real-Life (IRL) Planter Kits and Digital NFT Twins</h2>
                                    </div>
                                    <ul className="benifit-list benifit-one">
                                        <li>
                                            <i className="icon"><img src={require('../../static/images/icon-1.png')} alt='' /></i>
                                            CheebaPet IRL Planter Kits
                                        </li>
                                        <li>
                                            <i className="icon"><img src={require('../../static/images/icon-2.png')} alt='' /></i>
                                            Collect NFTs and Grow In-Real-Life Plants
                                        </li>
                                        <li>
                                            <i className="icon"><img src={require('../../static/images/icon-3.png')} alt='' /></i>
                                            10 OG CheebaPet Strains
                                        </li>
                                        <li>
                                            <i className="icon"><img src={require('../../static/images/icon-4.png')} alt='' /></i>
                                            IRL Planter Kits Just Add Water
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className='planet-sec' id='planet'>
                    <div className="auto-container">
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='sec-title'>
                                    <h2>CheebaPet IRL Planters Kits – Alpha Collection</h2>
                                    <h3>Complete Grow Kits, Just Add Water<br />Purchase an OG Trading Card to Receive 1 Free IRL Planter Kit per Card</h3>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='counter-box'>
                                    <h4>6/01 Drop Date:</h4>
                                    <div className='counterinner'>
                                        {isIrlPlanterCounter
                                            ? <h2>IRL Planters Sale Live</h2>
                                            : <>
                                                <i className='icon'><img src={require('../../static/images/time-icon.png')} alt='' /></i>
                                                {irlPlanterTime > 0 &&
                                                    <Countdown date={Date.now() + parseInt((irlPlanterTime * 1000) - Date.now())} renderer={renderer} onComplete={() => setIsIrlPlanterCounter(true)} />
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-12'>
                                <div className='nft-box'>
                                    <div className='overlaybox'>
                                        <h2>CheebaPet 3D NFT Planters</h2>
                                        <h3>Paired with IRL Matching Planter Kits</h3>
                                    </div>
                                    <img src={require('../../static/images/nft-plantes.png')} alt='' />
                                </div>
                            </div> */}

                        </div>
                        <IrlPlanterProducts page="home" />
                    </div>
                </section>

                <section className='planet-sec tranding-black-sec' id='planet'>
                    <div className="auto-container">
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='sec-title'>
                                    <h2>CheebaPet NFT Planters – Alpha Collection</h2>
                                    <h3>Purchase an IRL CheebaPet Planter Kit and Claim a Free NFT Planter</h3>
                                        {/* <h3>Purchase an IRL CheebaPet Planter Kit and Claim a Free NFT Planter<br />NFT Planters will Unlock Exclusive Benefits in Cheeba.World on<br />10/10 Happiness to Humans Day </h3> */}
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='counter-box'>
                                    <h4>Drop Date:</h4>
                                    <div className='counterinner'>
                                        {isPlanterCounter
                                            ? <h2>Sale Not Live Yet</h2>
                                            : <>
                                                <i className='icon'><img src={require('../../static/images/time-icon.png')} alt='' /></i>
                                                <Countdown date={Date.now() + parseInt((1719789600 * 1000) - Date.now())} renderer={renderer} onComplete={() => setIsPlanterCounter(true)} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-12'>
                                <div className='nft-box'>
                                    <div className='overlaybox'>
                                        <h2>CheebaPet 3D NFT Planters</h2>
                                        <h3>Paired with IRL Matching Planter Kits</h3>
                                    </div>
                                    <img src={require('../../static/images/nft-plantes.png')} alt='' />
                                </div>
                            </div> */}

                        </div>
                        <NftPlanters />
                    </div>
                </section>
            </div>
            <Footer />

        </div >
    );
}

export default Planters;