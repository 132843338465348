import ReactGA from "react-ga4";
import EventBus from 'eventing-bus';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectWallet, useConnectionStatus, useAddress, useSDK, useDisconnect, useChain } from '@thirdweb-dev/react';

import './index.css';
import Modals from '../modals';
import Preloader from '../../components/preloader';
import { networkId, message } from '../../store/config';

import { signIn, signOut, toggleGoogleAnalytics, toggleReviewCartModal, checkNetwork } from '../../store/actions/Auth';

const Navbar = ({ activeSection, sticky }) => {
    const sdk = useSDK();
    const chain = useChain();
    const address = useAddress();
    const dispatch = useDispatch();
    const disconnect = useDisconnect();
    const connectionStatus = useConnectionStatus();

    const { irlPlanterTime } = useSelector(st => st.NFT);

    let { cartItems, saleStage, nextSaleStartTime } = useSelector(st => st.NFT);
    let { isLoader, auth, publicAddress, isGoogleAnalytics } = useSelector(st => st.Auth);

    useEffect(() => {
        if (connectionStatus === "connected" && (address !== publicAddress)) setTimeout(() => disconnectWallet(), 500);
    }, [address]);

    const disconnectWallet = () => {
        dispatch(signOut());
        window.location.replace("/");
        EventBus.publish('info', 'Wallet Disconnected');
        return disconnect();
    };

    useEffect(() => {
        if (isGoogleAnalytics) {
            // Google Analytics
            ReactGA.initialize('G-TSB0ZT5WXT');
            dispatch(toggleGoogleAnalytics(false));
        }
    }, [isGoogleAnalytics]);

    useEffect(() => {
        window.scrollTo({ top: 0 })
        if (connectionStatus === "connected") setTimeout(() => handleConnectWallet(), 500);
        if (connectionStatus && connectionStatus == "disconnected") dispatch(signOut());
    }, [connectionStatus]);

    const handleTradingCounter = () => {
        let currentTime = new Date(Date.now() + 2000);
        let saleTime = new Date(nextSaleStartTime * 1000);

        if ((saleStage == "Sale not started yet" || saleStage == "Allowlist Sale") && currentTime > saleTime) {
            window.location.reload();
        }
    };

    const handleConnectWallet = (e) => {
        if (!auth && connectionStatus === "connected") {
            if ((chain?.['chainId']) !== networkId) {
                dispatch(checkNetwork(false));
                EventBus.publish('info', message);
                return disconnect();
            }
            else {
                dispatch(checkNetwork(true));
                signatureMessage();
            }
        }
    };

    const signatureMessage = async () => {
        try {
            let message = `cheebapet.io wants you to sign in with your wallet`;
            let result = await sdk?.wallet.sign(message);
            if (result) dispatch(signIn({ publicAddress: address, signature: result }));
        } catch (error) {
            disconnect();
            console.error("Error signing message:", error);
        }
    };

    return (
        <div className={`cheeba-nft-collection-nav ${sticky && 'sticky-nav'}`}>
            {(connectionStatus == 'connecting' || isLoader) && <Preloader />}
            {nextSaleStartTime > 0 && <Countdown className='d-none' date={Date.now() + parseInt((Number(nextSaleStartTime) * 1000) - Date.now())} onComplete={() => handleTradingCounter()} />}
            <Modals />
            <nav className='navbar navbar-expand-lg sidenav' id="sidenav-1" data-mdb-hidden="false">
                <div className=" inner-container">
                    <Link className='navbar-brand' to='/'><img src={require('../../static/images/logo.png')} alt='' /></Link>
                    <button onClick={() => this.setState({ isOpen: true })} className='navbar-toggler' type='button' data-toggle='collapse'
                        data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                        aria-label='Toggle navigation' aria-haspopup="true">
                        <i className='icon' aria-hidden='true'><img src={require('../../static/images/mobile-menu.png')} alt='' /></i>
                    </button>
                    <div className='collapse navbar-collapse nav-links' id='navbarSupportedContent'>
                        <div className="mobile-menu-logo">
                            <Link className='navbar-brand' to='/'><img src={require('../../static/images/logo.png')} alt='' /></Link>
                            <button onClick={() => this.setState({ isOpen: true })} className='navbar-toggler' type='button' data-toggle='collapse'
                                data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                aria-label='Toggle navigation' aria-haspopup="true">
                                <i className='icon' aria-hidden='true'><img src={require('../../static/images/menu-close.png')} alt='' /></i>
                            </button>
                        </div>
                        <ul className='navbar-nav sidenav-menu'>
                            <div className="searchbox">
                                <li className='nav-item'>
                                    <Link smooth to="/Planters" className={`nav-link ${activeSection == 'Planters' && 'active'}`}>
                                        Planters
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link smooth to="/TradingCards" className={`nav-link ${activeSection == 'TradingCards' && 'active'}`}>
                                        Trading Cards
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link smooth to="/RoadMap" className={`nav-link ${activeSection == 'Roadmap' && 'active'}`}>
                                        Roadmap
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link smooth to="/Rewards" className={`nav-link ${activeSection == 'Rewards' && 'active'}`}>
                                        Rewards
                                    </Link>

                                </li>

                                <li className='nav-item'>
                                    <Link smooth to="/allowlist" className={`nav-link ${activeSection == 'allowlist' && 'active'}`}>
                                        AllowList
                                    </Link>

                                </li>


                                <li className='nav-item'>
                                    {auth && <Link smooth to="/Profile" className={`nav-link ${activeSection == 'Profile' && 'active'}`}>
                                        Profile
                                    </Link>
                                    }
                                </li>
                                <li>
                                    {/* {connectionStatus == 'connecting' && <Preloader />} */}
                                    {(connectionStatus == 'disconnected' || connectionStatus == 'connected') && (
                                        <ConnectWallet
                                            theme={"dark"}
                                            modalSize={"wide"}
                                            switchToActiveChain={true}
                                            modalTitle={"CheebaPet NFT"}
                                        />
                                    )}
                                </li>
                                <li>
                                    {auth && <button onClick={() => dispatch(toggleReviewCartModal(true))} className="shop-icon">
                                        <img src={require('../../static/images/shop-icon.png')} alt='' />
                                        {cartItems && Object.keys(cartItems).length > 0 &&
                                            <i className='counter-icon'>{Object.keys(cartItems).length}</i>
                                        }
                                    </button>
                                    }
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}
export default Navbar;