import Slider from "react-slick";
import EventBus from 'eventing-bus';
import ReactSelect from 'react-select';
import { Link } from 'react-router-dom';
import { validate } from 'email-validator';
import { FormControl } from '@mui/material';
import countryList from 'react-select-country-list';
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useConnectionStatus } from '@thirdweb-dev/react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getUserProfile, toggleLoader } from '../../store/actions/Auth';
import { getClaimableRewards, claimRewards, getClaimedRewards, toggleCongratsModal, setClaimableRewards, setClaimedRewards } from '../../store/actions/NFT';


import './index.css';
import 'animate.css';
import '../../static/css/animate.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

const Reward = () => {
    const dispatch = useDispatch();
    let { userProfile, auth } = useSelector(st => st.Auth);
    let { claimableReward, irlPlantersJSON, claimedRewards, irlPlanterTime, congratsModal } = useSelector(st => st.NFT);

    const connectionStatus = useConnectionStatus();

    const [sticky, setSticky] = useState(false);
    const [claimReward, setClaimReward] = useState({});
    const [isIrlPlanterCounter, setIsIrlPlanterCounter] = useState(false);

    if (irlPlanterTime > 0 && (irlPlanterTime * 1000) < Date.now() && isIrlPlanterCounter == false) setIsIrlPlanterCounter(true);

    const modalSlider = { infinite: false, slidesToShow: 4, slidesToScroll: 1, dots: true, arrows: false, focusOnSelect: true, loop: false };

    const countryOptions = countryList().getData();
    const [formFields, setFormFields] = useState({
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        zipCode: '',
        countryState: '',
        country: '',
    });

    useEffect(() => { if (connectionStatus && connectionStatus === "disconnected") dispatch(setClaimedRewards({})) }, [connectionStatus]);

    useEffect(() => {
        if (auth) {
            dispatch(toggleLoader(true));
            dispatch(getUserProfile());
            dispatch(getClaimableRewards());
        }
    }, [auth]);

    useEffect(() => {
        setClaimReward(claimableReward);
        if (Object.values(claimableReward).length === 0) {
            dispatch(getClaimedRewards());
        }
    }, [claimableReward]);

    // useEffect(() => { if (irlPlanterTime > 0 && (irlPlanterTime * 1000) > Date.now()) window.location.replace("/") }, [irlPlanterTime])

    const onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) setSticky(true);
        if (pageYOffset < 20) setSticky(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e['target'];
        setFormFields(prevState => ({ ...prevState, [name]: value }));
    };

    const setCountry = (value) => {
        setFormFields({ ...formFields, country: value });
    };

    const claimingRewards = () => {
        try {
            const isValidZipCode = (str) => typeof str === 'string' && !/[a-zA-Z]/.test(str);
            const isValidString = (str) => typeof str === 'string' && /^[a-zA-Z\s]+$/.test(str);
            const { email, phone, firstName, lastName, address1, address2, city, zipCode, countryState, country } = formFields;

            if (!email || !phone || !firstName || !lastName || !address1 || !city || !countryState || !zipCode) {
                return EventBus.publish('error', "Please fill out all required fields.");
            }

            if (!validate(email)) return EventBus.publish('error', `Please provide a valid email address`);
            if (!isValidString(countryState)) return EventBus.publish('error', `Please provide a valid country state`);
            if (!isValidString(city)) return EventBus.publish('error', `City name doesn't have alphanumeric characters`);
            if (!isValidZipCode(zipCode)) return EventBus.publish('error', `Zip Code doesn't have alphabatic characters`);
            if (!isValidString(lastName)) return EventBus.publish('error', `LastName doesn't have alphanumeric characters`);
            if (!isValidString(firstName)) return EventBus.publish('error', `FirstName doesn't have alphanumeric characters`);


            dispatch(claimRewards({ shippingEmail: email, phoneNumber: phone, firstName, lastName, address1, address2, city, state: countryState, country, zipCode }));
            dispatch(toggleLoader(true));

            setFormFields({
                email: '',
                phone: '',
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                city: '',
                zipCode: '',
                countryState: '',
                country: '',
            });

        } catch (e) { console.error('Error claiming rewards:', e); }
    };

    return (
        <div className='cheeba-nft-collection-page ' onWheel={onScroll}>
            <div className="cheeba-rewards-page">
                <Navbar sticky={sticky} />
                <section className='banner-sec style-two planter-page'>
                    <div className="auto-container">
                        <div className='row'>
                            <div className='col-12'>
                                <div className='inner-container'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='nft-box-banner center-box rewards-box-2'>
                                                <div className="img-box">
                                                    <img src={require('../../static/images/rewards-banner-2.png')} alt='' />
                                                </div>
                                                <h3>Purchase OG NFT Trading Cards<br />to Qualify</h3>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="title-area">
                                                <h2>REWARDS</h2>
                                                <h3>Claim a Free IRL CheebaPet Planter Kit <br />for Each OG Trading Card Owned </h3>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='nft-box-banner center-box rewards-box-2 img-reward-center'>
                                                <div className="img-box">
                                                    <img src={require('../../static/images/rewards-banner-1.png')} alt='' />
                                                </div>
                                                <h3>IRL Grow at Home Planter Kits<br /><span>(U.S. Shipments Only)</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='planet-sec reward-page' >
                    <div className="auto-container">
                        <div className='row'>
                            {connectionStatus == 'disconnected' && <>
                                <div className='col-12'>
                                    <div className='sec-title reward-connect-wallet green-title text-center'>
                                        <h2>Connect Wallet to Verify Ownership of OG NFT Trading Card(s)<br />and Claim IRL Planter Kit(s)</h2>
                                        {/* <h3>Offer Only Valid June 1 to June 30, 2024</h3> */}
                                        <h3>Shipping Limited to United States Only</h3>
                                    </div>
                                </div>
                            </>}
                            <div className='col-lg-12'>
                                {connectionStatus == 'connected' && <>
                                    {userProfile?.['metadataNFT'] && userProfile?.['metadataNFT'].length > 0 &&
                                        <div className='text-block'>
                                            <p>Trading Card(s) Owned</p>
                                        </div>
                                    }
                                    <div className='row'>
                                        {userProfile?.['metadataNFT'] && userProfile?.['metadataNFT'].length > 0 ?
                                            userProfile?.['metadataNFT'].map((nftData, index) => (<>
                                                <div className="col" key={index}>
                                                    <a href={`/NftTradingCard#${nftData['nftID']}`}>
                                                        <div className="tranding-box reward-box">
                                                            <div className='flip-box'>
                                                                <div class="flip-box-front">
                                                                    <div className='img-box'>
                                                                        <img src={nftData?.['front']} alt='' />
                                                                    </div>
                                                                </div>
                                                                <div class="flip-box-back">
                                                                    <div className='img-box'>
                                                                        <img src={nftData?.['back']} alt='' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='text-box'>
                                                                <h4>{nftData?.['name']}</h4>
                                                                <div className='price-area'>
                                                                    <p>Quantity: <b>{nftData['nftQuantity']}</b></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </>
                                            )) : <h4 className='not-found'>No Rewards Found!</h4>
                                        }
                                    </div>
                                </>}
                                {(Object.keys(claimReward).length > 0 && isIrlPlanterCounter == true) && (
                                    <>
                                        <div className='text-block style-two'>
                                            <h3>Claim Your IRL Planter Kit(s)</h3>
                                        </div>
                                        <div className='row'>
                                            {claimReward && irlPlantersJSON && Object.entries(claimReward).map(reward => {
                                                return (
                                                    <div className="col" index={reward[0]}>
                                                        <div className='tranding-box reward-box'>
                                                            <div className='flip-box'>
                                                                <div class="flip-box-front">
                                                                    <div className='img-box'>
                                                                        <img src={irlPlantersJSON[Number(reward[0]) - 1]['frontImage']} alt='' />
                                                                    </div>
                                                                </div>
                                                                <div class="flip-box-back">
                                                                    <div className='img-box'>
                                                                        <img src={irlPlantersJSON[Number(reward[0]) - 1]['backImage']} alt='' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='text-box'>
                                                                <h4>{`${irlPlantersJSON[Number(reward[0]) - 1]['name']} #${reward[0]}`}</h4>
                                                                <div className='price-area'>
                                                                    <p>Quantity: <b>{Number(reward[1])}</b></p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                )}
                                {/* <div className='text-block'>
                                    <button className='btn-style-three'>Claim</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                {(Object.keys(claimReward).length === 0 && Object.keys(claimedRewards).length > 0 && isIrlPlanterCounter == true) && (
                    <section className='planet-sec reward-page' >
                        <div className="auto-container">
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='text-block style-three'>
                                        <h3><i className='icon'><img src={require('../../static/images/check.png')} alt='' /></i>Congratulations !</h3>
                                        <p>You have successfully claimed your complimentary IRL Planter Kit(s). <br />You will receive an email confirmation once your Planter Kit(s) has shipped.</p>
                                    </div>
                                    <div className='row'>
                                        {(claimedRewards && isIrlPlanterCounter == true) && Object.entries(claimedRewards).map(reward => {
                                            return (
                                                <div className="col" index={reward[0]}>
                                                    <div className='tranding-box reward-box'>
                                                        <div className='flip-box'>
                                                            <div class="flip-box-front">
                                                                <div className='img-box'>
                                                                    <img src={irlPlantersJSON[Number(reward[0]) - 1]['frontImage']} alt='' />
                                                                </div>
                                                            </div>
                                                            <div class="flip-box-back">
                                                                <div className='img-box'>
                                                                    <img src={irlPlantersJSON[Number(reward[0]) - 1]['backImage']} alt='' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='text-box'>
                                                            <h4>{`${irlPlantersJSON[Number(reward[0]) - 1]['name']} #${reward[0]}`}</h4>
                                                            <div className='price-area'>
                                                                <p>Quantity: <b>{Number(reward[1])}</b></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {(Object.keys(claimReward).length > 0 && isIrlPlanterCounter == true) &&
                    <section className='allowlist-sec claim-from-sec'>
                        <div className="auto-container">
                            <div className='row'>
                                <div className='col-lg-4 offset-lg-4 col-md-12'>
                                    <div className='form-box'>
                                        <h3>Claim Your Planter</h3>
                                        <p>Enter Your Shipping Details</p>
                                        <form>
                                            <div className='row'>
                                                <div className='col-lg-6 col-md-12'>
                                                    <div className="group-form">
                                                        <input
                                                            type="email"
                                                            placeholder='Enter your Email'
                                                            name="email"
                                                            value={formFields['email']}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-lg-6 col-md-12'>
                                                    <div className="group-form">
                                                        <input
                                                            type="phone"
                                                            placeholder='Enter your Phone'
                                                            name="phone"
                                                            value={formFields['phone']}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-lg-6 col-md-12'>
                                                    <div className="group-form">
                                                        <input
                                                            type="text"
                                                            placeholder='First Name'
                                                            name="firstName"
                                                            value={formFields['firstName']}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-12'>
                                                    <div className="group-form">
                                                        <input
                                                            type="text"
                                                            placeholder='Last Name'
                                                            name="lastName"
                                                            value={formFields['lastName']}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className="group-form">
                                                        <input
                                                            type="text"
                                                            placeholder='Address 1'
                                                            name="address1"
                                                            value={formFields['address1']}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className="group-form">
                                                        <input
                                                            type="text"
                                                            placeholder='Address 2'
                                                            name="address2"
                                                            value={formFields['address2']}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-12'>
                                                    <div className="group-form">
                                                        <input
                                                            type="text"
                                                            placeholder='Zip Code'
                                                            name="zipCode"
                                                            value={formFields['zipCode']}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-12'>
                                                    <div className="group-form">
                                                        <input
                                                            type="text"
                                                            placeholder='City'
                                                            name="city"
                                                            value={formFields['city']}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className="group-form">
                                                        <input
                                                            type="text"
                                                            placeholder='State'
                                                            name="countryState"
                                                            value={formFields['countryState']}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className='col-12'>
                                                    <div className="group-form">
                                                        <FormControl fullWidth>
                                                            <ReactSelect
                                                                key={formFields['country'] ? 'selected' : 'default'}
                                                                options={countryOptions}
                                                                value={countryOptions.find(option => option['value'] === formFields['country'])}
                                                                onChange={(selectedOption) => setCountry(selectedOption['label'])}
                                                                placeholder="Select Your Country"
                                                                menuContainerStyle={{'zIndex': 999}}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div> */}
                                                <div className='col-12 text-center'>
                                                    <div className="group-form">
                                                        <button type="button" className='btn-style-one' onClick={claimingRewards}>Claim Reward</button>
                                                    </div>
                                                </div>
                                                <div className="form-area">
                                                    <p className='mt-3 bottom-text'>By purchasing this irl planter, you acknowledge that you have read and accept our <Link to="/TermsandCondition" target="_blank">terms of services</Link></p>
                                                </div>
                                            </div>
                                        </form>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </div>
            <Footer />

            {/* ------------Congratulations MODAL------------ */}
            <Modal
                centered
                className='modal-login style-two congratulations-modal'
                isOpen={congratsModal}
                toggle={() => { dispatch(toggleCongratsModal(false)); dispatch(setClaimableRewards({})) }}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalHeader><i className='icon'><img className='check-img' src={require('../../static/images/check.png')} alt='' /></i>Congratulations</ModalHeader>
                <ModalBody className='modal-body'>
                    <div className="modal-area tranding-sec">
                        <div className='tranding-cards'>
                            <Slider
                                {...modalSlider}>
                                {claimReward && irlPlantersJSON && Object.entries(claimReward).map(reward => {
                                    return (
                                        <div className='item' index={reward[0]}>
                                            <div className='tranding-box'>
                                                <div className="flip-box">
                                                    <div className="flip-box-front">
                                                        <div className='img-box'>
                                                            <img className='front-img' src={irlPlantersJSON?.[Number(reward[0]) - 1]?.['frontImage']} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className="flip-box-back">
                                                        <div className='img-box'>
                                                            <img className='back-img' src={irlPlantersJSON?.[Number(reward[0]) - 1]?.['backImage']} alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='text-box'>
                                                    <h4>{`${irlPlantersJSON?.[Number(reward[0]) - 1]?.['name']} #${reward[0]}`}</h4>
                                                    <div className='price-area'>
                                                        <p>Quantity: <b>{Number(reward[1])}</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                        <div className="detail-area">
                            <p>You will receive an email confirmation once your IRL Planter Kit(s) has shipped</p>
                            <p style={{ fontWeight: '400' }}>Please visit the the Profile Page to view your collections.</p>
                            <div className='row d-flex justify-content-center modal-confrom-btn'>
                                <button className="btn-style-one mr-3"
                                    onClick={() => { dispatch(toggleCongratsModal(false)); dispatch(setClaimableRewards({})) }}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div >
    );
}

export default Reward;