import { useDispatch } from 'react-redux';
import React, { useState, useEffect, useRef } from "react";

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import Allowlist from '../../components/allowlist';

const PrivacyPolicy = () => {

    const [sticky, setSticky] = useState(false);
    
    const onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) setSticky(true);
        if (pageYOffset < 20) setSticky(false);
    };


    return (
        <div className='cheeba-nft-collection-page' onWheel={onScroll}>
            <div>
                <Navbar sticky={sticky} />
                <section className='privacy-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h3>Privacy Policy</h3>
                                <p><b>Effective as of:</b> April 1, 2024</p>
                                <div className='privacy-box'>
                                    <p>This Privacy Policy describes how CheebaPet, Inc. (“<b>Company,</b>” “we”, “<b>us</b>” or “<b>our</b>”) and our affiliates handle personal information that we collect through our digital properties that link to this Privacy Policy, including our website (collectively, the “<b>Service</b>”), as well as through social media, our marketing activities, live events and other activities described in this Privacy Policy.</p>
                                </div>
                                <div className='privacy-box'>
                                    <h4>Personal information we collect</h4>
                                    <p><b>Information you provide to us.</b> Personal information you may provide to us through the Service or otherwise includes:</p>
                                    <ul>
                                        <li>Contact data, such as your first and last name, salutation, email address, billing and mailing addresses, and phone number.</li>
                                        <li>Demographic data, such as your city, state, country of residence, postal code, and age.</li>
                                        <li>Profile data, such as the wallet address and username or password used to establish an online account on the Service, links to your profiles on social networks, and any other information that you add to your account profile.</li>
                                        <li>Communications that we exchange with you, including when you contact us through the Service, social media, or otherwise.</li>
                                        <li>Transactional data, such as blockchain transaction history and information relating to or needed to complete your specific NFT purchases or sales on or through the Service.</li>
                                        <li>User-generated content, such as profile pictures, photos, images, music, videos, comments, questions, messages, works of authorship, and other content or information that you generate, transmit, or otherwise make available on the Service, as well as associated metadata. Metadata includes information on how, when, where and by whom a piece of content was collected and how that content has been formatted or edited. Metadata also includes information that users can add or can have added to their content, such as keywords, geographical or location information, and other similar data.</li>
                                        <li>Financial data, such as your virtual currency or wallet accounts, stored value accounts, royalties, fees, sales prices, sell-through data, and associated details.</li>
                                        <li>Other data not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.</li>
                                    </ul>

                                    <p><b>Third-party sources.</b> We may combine personal information we receive from you with personal information we obtain from other sources, such as:</p>
                                    <ul>
                                        <li>Public sources, such as government agencies, public records, social media platforms, public blockchain(s), and other publicly available sources.</li>
                                        <li>Data providers, such as information services and data licensors that provide demographic and other information.</li>
                                        <li>NFT marketplaces, such as Opensea, provide information that allows us to collect, store, and display NFTs purchased by you on or through the Service.</li>
                                        <li>Our affiliate partners, such as our affiliate network provider and publishers, influencers, and promoters who participate in our paid affiliate programs.</li>
                                        <li>Marketing partners, such as joint marketing partners and event co-sponsors.</li>
                                        <li>Third-party services, such as third-party services that you use to log into, or otherwise link to, your Service account. This data may include your username, profile picture and other information associated with your account on that third-party service that is made available to us based on your account settings on that service.</li>
                                    </ul>

                                    <p><b>Automatic data collection.</b> We, our service providers, and our business partners may automatically log information about you, your computer or mobile device, and your interaction over time with the Service, our communications and other online services, such as:</p>
                                    <ul>
                                        <li>Device data, such as your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings, mobile device carrier, radio/network information (e.g., Wi-Fi, LTE, 3G), and general location information such as city, state or geographic area.</li>
                                        <li>Online activity data, such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the Service, navigation paths between pages or screens, information about your activity on a page or screen, access times and duration of access, and whether you have opened our emails or clicked links within them.</li>
                                        <li>Location data when you authorize the Service to access your device’s location.</li>
                                    </ul>

                                    <p><b>Cookies and similar technologies.</b>  Some of the automatic collection described above is facilitated by the following technologies:</p>
                                    <ul>
                                        <li>Cookies, which are small text files that websites store on user devices and that allow web servers to record users’ web browsing activities and remember their submissions, preferences, and login status as they navigate a site. Cookies used on our sites include both “session cookies” that are deleted when a session ends, “persistent cookies” that remain longer, “first party” cookies that we place and “third party” cookies that our third-party business partners and service providers place.</li>
                                        <li>Local storage technologies, like HTML5 and Flash, provide cookie-equivalent functionality but can store larger amounts of data on your device outside of your browser in connection with specific applications.</li>
                                        <li>Web beacons, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email address was accessed or opened, or that certain content was viewed or clicked.</li>
                                        <li>Software development kits. An SDK is third-party computer code that we may incorporate into our applications that may be used for a variety of purposes, including to provide us with analytics regarding the use of our applications, to integrate with social media, add features or functionality to our app, or to facilitate online advertising.</li>
                                    </ul>

                                    <p><b>Data about others.</b>  We may offer features that help users invite their friends or contacts to use the Service, and we may collect contact details about these invitees so we can deliver their invitations. Please do not refer someone to us or share their contact details with us unless you have their permission to do so.</p>
                                </div>
                                <div className='privacy-box'>
                                    <h4>How we use your personal information</h4>
                                    <p>We may use your personal information for the following purposes or as otherwise described at the time of collection:</p>

                                    <p><b>Service delivery.</b> We may use your personal information to:</p>
                                    <ul>
                                        <li>Provide, operate and improve the Service and our business;</li>
                                        <li>Establish and maintain your user profile or history of activity on the Service;</li>
                                        <li>Facilitate your invitations to friends who you want to invite to join the Service;</li>
                                        <li>Enable security features of the Service and remembering devices from which you have previously logged in;</li>
                                        <li>Communicate with you about the Service, including by sending announcements, updates, security alerts, and support and administrative messages;</li>
                                        <li>Understand your needs and interests, and personalize your experience with the Service and our communications; and</li>
                                        <li>Provide support for the Service, and respond to your requests, questions and feedback.</li>
                                    </ul>

                                    <p><b>Research and development.</b>   We may use your personal information for research and development purposes, including to analyze and improve the Service and our business.</p>
                                    <p><b>Marketing.</b>  We, and our service providers, may collect and use your personal information for direct marketing communications. You may opt-out of our marketing communications as described in the <b>Opt-out of marketing</b> section below.</p>
                                    <p><b>Compliance and protection.</b> We may use your personal information to:</p>
                                    <ul>
                                        <li>Comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities;</li>
                                        <li>Protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);</li>
                                        <li>Audit our internal processes for compliance with legal and contractual requirements or our internal policies;</li>
                                        <li>Enforce the terms and conditions that govern the Service; and</li>
                                        <li>Prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.</li>
                                    </ul>
                                    <p><b>With your consent.</b>  In some cases, we may specifically ask for your consent to collect, use or share your personal information, such as when required by law.</p>
                                    <p><b>To create anonymous, aggregated or de-identified data.</b>  We may create anonymous, aggregated or de-identified data from your personal information and other individuals whose personal information we collect. We make personal information into anonymous, aggregated or de-identified data by removing information that makes the data identifiable to you. We may use this anonymous, aggregated or de-identified data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.</p>
                                    <p><b>Cookies and similar technologies.</b>  In addition to the other uses included in this section, we may use the <b>Cookies and similar technologies</b> described above for the following purposes:</p>
                                    <div className='box-inner'>
                                        <p><b>Technical operation.</b> To allow the technical operation of the Service, such as by remembering your selections and preferences as you navigate the site, and whether you are logged in when you visit password protected areas of the Service.</p>
                                        <p><b>Analytics.</b> To help us understand user activity on the Service, including which pages are most and least visited and how visitors move around the Service, as well as user interactions with our emails.</p>
                                    </div>
                                </div>
                                <div className='privacy-box'>
                                    <h4>How we share your personal information</h4>
                                    <p>We may share your personal information with the following parties and as otherwise described in this Privacy Policy or at the time of collection.</p>

                                    <p><b>Affiliates.</b> Our corporate parent, subsidiaries, and affiliates, for purposes consistent with this Privacy Policy.</p>
                                    <p><b>Service providers.</b> Third parties that provide services on our behalf or help us operate the Service or our business (such as hosting, information technology, customer support, email delivery, marketing, consumer research and website analytics).</p>
                                    <p><b>Marketplace partners.</b> We may share your personal information with our third-party NFT marketplaces in order to facilitate the display of NFTs on or through the Service, as well as the purchase and/or sale of NFTs, such as through integrations with OpenSea and others.</p>
                                    <p><b>Cryptocurrency or payment platforms.</b> Any information collected necessary to process an NFT purchase (such as your wallet address) is collected and processed directly by your chosen cryptocurrency or payment platform. Please review the privacy policies for the relevant cryptocurrency or payment platform to learn how they may use your payment information.</p>
                                    <p><b>Linked third-party services.</b> If you log into the Service with another third-party service, we may share your personal information with that third-party service. The third party’s use of the shared information will be governed by its privacy policy and the settings associated with your account with the third-party service.</p>
                                    <p><b>Professional advisors.</b> Professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the professional services that they render to us.</p>
                                    <p><b>Authorities and others.</b> Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate for the <b>compliance and protection purposes</b> described above.</p>
                                    <p><b>Business transferees.</b> Acquirers and other relevant participants in business transactions (or negotiations of or due diligence for such transactions) involving a corporate divestiture, merger, consolidation, acquisition, reorganization, sale or other disposition of all or any portion of the business or assets of, or equity interests in, Company or our affiliates (including, in connection with a bankruptcy or similar proceedings).</p>
                                    <p><b>Other users and the public.</b> Your profile and other user-generated content (except for private messages) are visible to other users of the Service and the public. For example, other users of the Service or the public may have access to your information if you chose to make your profile or other personal information available to them through the Service, such as when you provide comments, reviews, survey responses, share NFTs, interact with other users, or share other content. This information can be seen, collected and used by others, including being cached, copied, screen captured or stored elsewhere by others (e.g., search engines), and we are not responsible for any such use of this information.</p>
                                </div>

                                <div className='privacy-box'>
                                    <h4>Your choices</h4>
                                    <p><b>Access or update your information.</b> If you have registered for an account with us through the Service, you may review and update certain account information by logging into the account.</p>
                                    <p><b>Opt-out of marketing communications.</b> You may opt-out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us. Please note that if you choose to opt-out of marketing-related emails, you may continue to receive service-related and other non-marketing emails.</p>
                                    <p><b>Do Not Track.</b> Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” or similar signals. </p>
                                    <p><b>Declining</b> to provide information. We need to collect personal information to provide certain services. If you do not provide the information we identify as required or mandatory, we may not be able to provide those services.</p>
                                    <p><b>Linked third-party platforms.</b> If you choose to connect to the Service through another third-party platform, you may be able to use your settings in your account with that platform to limit the information we receive from it. If you revoke our ability to access information from a third-party platform, that choice will not apply to information that we have already received from that third party.</p>
                                </div>

                                <div className='privacy-box'>
                                    <h4>Other Sites and Services</h4>
                                    <p>The Service may contain links to websites, mobile applications, and other online services operated by third parties. In addition, our content may be integrated into web pages or other online services that are not associated with us. These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party. We do not control websites, mobile applications or online services operated by third parties, and we are not responsible for their actions. We encourage you to read the privacy policies of the other websites, mobile applications and online services you use.</p>
                                </div>

                                <div className='privacy-box'>
                                    <h4>Security</h4>
                                    <p>We employ a number of technical, organizational and physical safeguards designed to protect the personal information we collect. However, security risk is inherent in all Internet and information technologies and we cannot guarantee the security of your personal information.</p>
                                </div>

                                <div className='privacy-box'>
                                    <h4>International data transfer</h4>
                                    <p>We are headquartered in the United States and may use service providers that operate in other countries. Your personal information may be transferred to the United States or other locations where privacy laws may not be as protective as those in your state, province, or country.</p>
                                </div>

                                <div className='privacy-box'>
                                    <h4>Children</h4>
                                    <p>The Service is not intended for use by anyone under 18 years of age. If you are a parent or guardian of a child from whom you believe we have collected personal information in a manner prohibited by law, please contact us. If we learn that we have collected personal information through the Service from a child without the consent of the child’s parent or guardian as required by law, we will comply with applicable legal requirements to delete the information.</p>
                                </div>
                                
                                <div className='privacy-box'>
                                    <h4>Changes to this Privacy Policy</h4>
                                    <p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service or by other appropriate means. Any modifications to this Privacy Policy will be effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your use of the Service after the effective date of any modified Privacy Policy indicates your acceptance of the modified Privacy Policy.</p>
                                </div>

                                <div className='privacy-box'>
                                    <h4>How to contact us</h4>
                                    <p><b>Email:</b> <a href='mailto:contact@cheebapet.com'>contact@cheebapet.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
                <Footer />

        </div >
    );
}

export default PrivacyPolicy;