import EventBus from "eventing-bus";
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import React, { useEffect, Suspense } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from './Home';
import Faqs from './Faqs';
import RoadMap from './RoadMap';
import Profile from './Profile';
import Rewards from './Rewards';
import Planters from './Planters';
import ComingSoon from './ComingSoon';
import IrlPlanters from "./IrlPlanters";
import TradingCards from './TradingCards';
import PrivacyPolicy from './PrivacyPolicy';
import NftTradingCard from './NftTradingCard';
import { signOut } from '../store/actions/Auth';
import Preloader from '../components/preloader';
import PrivateRoute from "../store/PrivateRoute";
import TermsandCondition from './TermsandCondition';
import { getSmartContracts } from "../store/actions/NFT";

import '../static/css/style.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";

const hist = createBrowserHistory();

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSmartContracts());
    EventBus.on('tokenExpired', () => dispatch(signOut()));
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  }, []);

  return (
    <div>
      <Suspense fallback={<Preloader />}>
        <ToastContainer closeOnClick position="bottom-left" />
        <Router history={hist}>
          <Switch>
            <Route exact path='/' component={props => <Home {...props} />} />
            <Route exact path='/Home' component={props => <Home {...props} />} />
            <Route exact path='/NftTradingCard' component={props => <NftTradingCard {...props} />} />
            <Route exact path='/IrlPlanters' component={props => <IrlPlanters {...props} />} />
            <Route exact path='/RoadMap' component={props => <RoadMap {...props} />} />
            <Route exact path='/Rewards' component={props => <Rewards {...props} />} />
            <Route exact path='/Faqs' component={props => <Faqs {...props} />} />
            <Route exact path='/Allowlist' component={props => <ComingSoon {...props} />} />
            <PrivateRoute exact path='/Profile' component={props => <Profile {...props} />} />
            <Route exact path='/TradingCards' component={props => <TradingCards {...props} />} />
            <Route exact path='/Planters' component={props => <Planters {...props} />} />
            <Route exact path='/PrivacyPolicy' component={props => <PrivacyPolicy {...props} />} />
            <Route exact path='/TermsandCondition' component={props => <TermsandCondition {...props} />} />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
