import axios from 'axios';
import Slider from "react-slick";
import ReactSelect from 'react-select';
import { Link } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from "react";

import EventBus from 'eventing-bus';
import { validate } from 'email-validator';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { getMetadata } from "../../store/actions/NFT";
import { toggleLoader } from "../../store/actions/Auth";

import { FormControl } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
// import { loadStripe } from '@stripe/stripe-js';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import IrlPlanterProducts from '../../components/IrlPlanterCards';

import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
// import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

// import { stripeKey } from '../../store/config';

import 'animate.css';
import './index.css';
import "react-toastify/dist/ReactToastify.css";

const IrlPlanters = () => {
    const planterId = window.location.href.split('#')[1];

    const dispatch = useDispatch();
    const [sticky, setSticky] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [planterData, setPlanterData] = useState([]);
    const [state, setState] = useState({ nav1: null, nav2: null });
    const [totalCost, setTotalCost] = useState(planterData?.['price'] || 0);

    const [show, setShow] = React.useState();
    const [buyNftPlantermodal, setModal] = useState(false);
    // const [stripePromise] = useState(loadStripe(stripeKey));
    const [congratsModal, setCongratsModal] = useState(false);

    let { auth } = useSelector(st => st.Auth);
    let { nftMetadata, irlPlantersJSON, irlPlanterTime } = useSelector(st => st.NFT);

    const [city, setCity] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [fName, setFName] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [lastName, setLastName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const countryOptions = countryList().getData();
    const [countryState, setCountryState] = useState('');

    let slider1 = useRef();
    let slider2 = useRef();
    const settingCarousel = {};
    const { nav1, nav2 } = state;
    const settingsSliderNav = { slidesToShow: 3, slidesToScroll: 1, dots: false, arrows: false, focusOnSelect: true, loop: false };
    const modalSlider = { infinite: false, slidesToShow: 4, slidesToScroll: 1, dots: true, arrows: false, focusOnSelect: true, loop: false };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (planterId) {
            dispatch(toggleLoader(true));
            dispatch(getMetadata({ nftId: planterId }));
        }

        setState({ nav1: slider1.current, nav2: slider2.current });
        dispatch(toggleLoader(true));

        let images = document.getElementsByTagName('img');
        let loaded = images.length;
        for (var i = 0; i < images.length; i++) {
            if (images[i].complete) loaded--;
            else {
                images[i].addEventListener('load', () => {
                    loaded--;
                    if (loaded === 0) setTimeout(() => dispatch(toggleLoader(false)), 1000);
                });
            }
            if (loaded === 0) setTimeout(() => dispatch(toggleLoader(false)), 1000);
        }
    }, []);

    useEffect(() => {
        if (planterId < 0 || planterId > 10) window.location.replace("/");
    }, [planterId]);

    useEffect(() => {
        if (irlPlantersJSON) {
            setPlanterData(irlPlantersJSON[planterId - 1]);
            setTotalCost(irlPlantersJSON[planterId - 1]?.['price']);
        };
    }, [irlPlantersJSON]);

    useEffect(() => {
        if (planterId < 0 || planterId > 10) window.location.replace("/");
    }, [planterId]);

    const resetFormFields = () => {
        setShow('');
        setCity('');
        setEmail('');
        setPhone('');
        setFName('');
        setZipCode('');
        setCountry('');
        setLastName('');
        setAddress1('');
        setAddress2('');
        setCountryState('');
    };

    // const CheckoutForm = () => {
    //     // const stripe = useStripe();
    //     const elements = useElements();
    //     const handleSubmit = async () => {
    //         try {
    //             const isValidZipCode = (str) => typeof str === 'string' && !/[a-zA-Z]/.test(str);
    //             const isValidString = (str) => typeof str === 'string' && /^[a-zA-Z\s]+$/.test(str);
    //             if (!email || !phone || !fName || !lastName || !address1 || !zipCode || !city || !countryState) {
    //                 return EventBus.publish('error', "Please fill out all required fields.");
    //             }

    //             if (!validate(email)) return EventBus.publish('error', `Please provide a valid email address`);
    //             if (!isValidString(countryState)) return EventBus.publish('error', `Please provide a valid country state`);
    //             if (!isValidString(city)) return EventBus.publish('error', `City name doesn't have alphanumeric characters`);
    //             if (!isValidZipCode(zipCode)) return EventBus.publish('error', `Zip Code doesn't have alphabatic characters`);
    //             if (!isValidString(fName)) return EventBus.publish('error', `FirstName doesn't have alphanumeric characters`);
    //             if (!isValidString(lastName)) return EventBus.publish('error', `LastName doesn't have alphanumeric characters`);

    //             const { error, paymentMethod } = await stripe.createPaymentMethod({ type: "card", card: elements.getElement(CardElement) });

    //             if (error) {
    //                 console.error('Stripe payment method creation failed:', error.message);
    //                 EventBus.publish('error', `Failed to create payment method: ${error.message}`);
    //                 return;
    //             }

    //             toggle();
    //             dispatch(toggleLoader(true));
    //             submitPayment(paymentMethod['id']).then(() => {
    //                 dispatch(toggleLoader(false));
    //                 resetFormFields();
    //             }).catch((submitError) => {
    //                 console.error('Submitting payment failed:', submitError);
    //                 EventBus.publish('error', `Failed to submit payment: ${submitError.message}`);
    //             });

    //         } catch (e) { console.error('Unhandled exception:', e); }
    //     }

    //     const submitPayment = async (methodId) => {
    //         try {
    //             const price = Number(quantity) * Number(planterData['price']);
    //             let stripeIntent;
    //             if (auth) {
    //                 stripeIntent = await axios.post('/nft/stripeIntent', { methodId, tokenIds: planterId, quantities: Number(quantity), price, email, phone, fName, lastName, address1, address2, zipCode, city, countryState, country, productType: 'irlPlanter' });
    //             } else {
    //                 stripeIntent = await axios.post('/nft/publicStripeIntent', { methodId, tokenIds: planterId, quantities: Number(quantity), price, email, phone, fName, lastName, address1, address2, zipCode, city, countryState, country, productType: 'irlPlanter' });
    //             }

    //             const { data } = stripeIntent;
    //             if (!data['body']) {
    //                 EventBus.publish('error', data['message']);
    //                 dispatch(toggleLoader(false));
    //                 return;
    //             }
    //             const transaction = await stripe?.confirmCardPayment(data['body']);
    //             if (transaction?.['error']) {
    //                 EventBus.publish('error', transaction['error']['message']);
    //                 dispatch(toggleLoader(false));
    //                 return;
    //             }
    //             return toggleCongratsModal();
    //         } catch (e) {
    //             console.log('****** e = ', e);
    //             dispatch(toggleLoader(false));
    //         }
    //     }

    //     const CARD_ELEMENT_OPTIONS = {
    //         style: {
    //             base: {
    //                 fontSize: "18px",
    //                 color: "#32325d",
    //                 fontSmoothing: "antialiased",
    //                 "::placeholder": { color: "#aab7c4" },
    //                 fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    //             }, invalid: { color: "#fa755a", iconColor: "#fa755a" }
    //         }
    //     }
    //     return (
    //         <>
    //             <div className='my-4'>
    //                 <CardElement options={CARD_ELEMENT_OPTIONS} />
    //             </div>
    //             <div className="group-form style-three" >
    //                 <button type="button" disabled={!stripe || !elements} onClick={() => handleSubmit()} className="card-btn">{`Buy Now`}</button>
    //             </div>
    //         </>
    //     );
    // };

    const handleQuantityChange = (e) => {
        const newQuantity = Number(e.target.value);
        setQuantity(newQuantity);
        const newTotalCost = newQuantity * planterData?.['price'];
        setTotalCost(newTotalCost);
    };

    const toggle = () => {
        if ((irlPlanterTime * 1000) > Date.now()) {
            EventBus.publish("info", "IRL Planter sale is not started yet");
            return;

        }

        setShow('');
        setModal(!buyNftPlantermodal);
    };

    const toggleCongratsModal = () => { setCongratsModal(!congratsModal); }

    const onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) setSticky(true);
        if (pageYOffset < 20) setSticky(false);
    };

    return (
        <div className='cheeba-nft-collection-page' onWheel={onScroll}>
            <div>
                <Navbar sticky={sticky} />
                <section className='product-detail-sec'>
                    <div className="auto-container">
                        <div className='row'>
                            <div className='col-xl-5 col-lg-6 col-md-12 col-sm-12'>
                                <div className="larg-slider">
                                    <Slider
                                        asNavFor={nav2}
                                        ref={slider => (slider1.current = slider)}
                                        {...settingCarousel}>
                                        <div>
                                            <div className="item">
                                                <div className="about-game-img">
                                                    {planterData?.['frontImage'] && <img src={planterData['frontImage']} alt='' />}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="item">
                                                <div className="about-game-img">
                                                    {planterData?.['backImage'] && <img src={planterData['backImage']} alt='' />}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="item">
                                                <div className="about-game-img">
                                                    {planterData?.['image'] && <img src={planterData['image']} alt='' />}
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                                <Slider className="slider-nav-thumbnails"
                                    asNavFor={nav1}
                                    ref={slider => (slider2.current = slider)}
                                    {...settingsSliderNav}>
                                    <div>
                                        <div className="item-thumbnail">
                                            <div className="about-game-img">
                                                {planterData?.['frontImage'] && <img src={planterData['frontImage']} alt='' />}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-thumbnail">
                                            <div className="about-game-img">
                                                {planterData?.['backImage'] && <img src={planterData['backImage']} alt='' />}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item-thumbnail">
                                            <div className="about-game-img">
                                                {planterData?.['image'] && <img src={planterData['image']} alt='' />}
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                            <div className="col-xl-7 col-lg-6 col-md-12">
                                <div className="right-content">
                                    {planterData?.['name'] && <h2>CheebaPet {`IRL Planters ${planterData['name']} #${planterId}`}</h2>}
                                    <div className="price-area">
                                        <p>Current Price:</p>
                                        {planterData?.['price'] && <h2>{`${planterData?.['price']} USD`}</h2>}
                                    </div>
                                    <div className='detail-area-planter'>
                                        <div className='img-box'>
                                            {planterData?.['displayImage'] && <img src={planterData?.['displayImage']} alt='' />}
                                        </div>
                                        <div className='text-box'>
                                            <p className='first-parhgraph'>Grow at home with your CheebaPet Planter Kit.  Everything you need to go from seed to harvest in less than 90 days.</p>
                                            <p>Each CheebaPet Strain Based Planter Kit Includes:</p>
                                            <ul>
                                                <li><i className='icon'><img src={require('../../static/images/check-icon-1.png')} alt='' /></i>Characterized Planter</li>
                                                <li><i className='icon'><img src={require('../../static/images/check-icon-1.png')} alt='' /></i>NutraSoil Pouch</li>
                                                <li><i className='icon'><img src={require('../../static/images/check-icon-1.png')} alt='' /></i>2 Auto-Flowering Seeds</li>
                                            </ul>
                                            <p>Easy to Plant.  Easy to Grow.  Just Add Water and Sunlight.</p>
                                            <p>Get to know your favorite CheebaPet Strains and grow them again and again.  Seed replenishment program coming soon.</p>
                                            {/* <p><b>Get Your Seeds in the Soil by 7/10 to Participate in<br />Special Celebration on 10/10 Happiness to Humans Day</b></p> */}
                                        </div>
                                    </div>
                                    <div className="btn-area text-center">
                                        <button onClick={() => toggle()} className="btn-style-one">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
                                <div className="bottom-slider">
                                    <div id="accordion">
                                        <div class="card">
                                            <div class="card-header" id="headingOne">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Traits
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseOne" class="collapse collapase-active-area show" aria-labelledby="headingOne" data-parent="#accordion">
                                                <div class="card-body">
                                                    <div className="traits-column">
                                                        {nftMetadata?.['attributes'] && nftMetadata?.['attributes'].length > 0
                                                            ? <>{nftMetadata?.['attributes'].map(item => {
                                                                return (
                                                                    <div className="traits-box">
                                                                        <p>{item['trait_type']}</p>
                                                                        <h4>{item['value'].replaceAll(',', ', ')}</h4>
                                                                    </div>
                                                                )
                                                            })}</>
                                                            : <h5 className="ml-4">No Traits Found!</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Details
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseTwo" class="collapse collapase-active-area" aria-labelledby="headingTwo" data-parent="#accordion">
                                                <div class="card-body">
                                                    <div className="detial-box">
                                                        <ul>
                                                            <li>Planter Name <span>{planterData?.['name'] && planterData?.['name']}</span></li>
                                                            <li>Token ID <span>{planterId}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="headingThree">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        About CheebaPet
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseThree" class="collapse collapase-active-area" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div class="card-body">
                                                    <div className="detial-box">
                                                        <p>CheebaPet IRL Planter Kits Alpha Collection are based upon the 10 CheebaPet OG’s and include everything needed for planting and growing each specific CheebaPet Strain. All seeds are Auto-Flowering and go from seed to harvest in under 90 days. No lighting schedules are required just the natural sunlight cycle with regular watering.</p>
                                                        <p>The Alpha Collection is only available from June 1 – 30, 2024 as a Limited Edition for the purpose of getting Seeds in the Soil to Harvest by the First Annual Happiness to Humans Day on 10/10. All Alpha Collection Planter purchasers will Receive a Free CheebaPet NFT Planter that will Unlock Special Benefits on 10/10 in the Cheeba.World metaverse</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='tranding-sec' id='nft-marketplace'>
                    <div className="auto-container">
                        <div className='row'>
                            <div className='col-12'>
                                <div className='sec-title'>
                                    <h2>More From This Collection</h2>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='tranding-cards'>
                                    <IrlPlanterProducts page="product" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
            {/* -----------------BUY NFT MODAL----------------- */}
            <Modal
                className='modal-login style-two modal-login ' isOpen={buyNftPlantermodal} toggle={toggle}
                aria-labelledby="contained-modal-title-vcenter" centered >
                <ModalHeader toggle={toggle}>NFT Planters</ModalHeader>
                <ModalBody className='modal-body'>
                    <div className={show ? "buy-block modal-area active" : "modal-area buy-block"}>
                        <ul className="step-box">
                            <li className={show ? "" : "active"}>Selected Planter</li>
                            <li className={show ? "active" : ""}>Shipping</li>
                        </ul>
                        <div className="shipping-info">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="buy-nft">
                                            <Checkbox checked={true} icon={<CircleUnchecked />} checkedIcon={<CircleChecked />} />
                                        </td>
                                        <td>
                                            <div className="about-game-img">
                                                <img src={require('../../static/images/mystery-box-11.png')} alt='' />
                                            </div>
                                        </td>
                                        <td><label htmlFor="option1">{`${planterData?.['name']} #${planterId}`}</label></td>
                                        <td>
                                            <input
                                                id="option1"
                                                name="number"
                                                type="number"
                                                min="1"
                                                defaultValue={1}
                                                value={quantity}
                                                onChange={(e) => handleQuantityChange(e)}
                                            />
                                        </td>
                                        <td>{totalCost} USD</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="next-btn text-center">
                                <button onClick={() => setShow(!show)} className="btn-style-one">Next</button>
                            </div>
                        </div>
                        <div className="card-info allowlist-sec">

                            <form>
                                <div className='row form-box'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="email"
                                                placeholder='Enter your Email'
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="phone"
                                                placeholder='Enter your Phone'
                                                name="phone"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='First Name'
                                                name="FName"
                                                value={fName}
                                                onChange={(e) => setFName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='Last Name'
                                                name="LastName"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='Address 1'
                                                name="Address1"
                                                value={address1}
                                                onChange={(e) => setAddress1(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='Address 2'
                                                name="Address2"
                                                value={address2}
                                                onChange={(e) => setAddress2(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='Zip Code'
                                                name="ZipCode"
                                                value={zipCode}
                                                onChange={(e) => setZipCode(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='City'
                                                name="City"
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='State'
                                                name="State"
                                                value={countryState}
                                                onChange={(e) => setCountryState(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className='col-12'>
                                        <div className="group-form">
                                            <FormControl fullWidth>
                                                <ReactSelect
                                                    options={countryOptions}
                                                    value={countryOptions.find(option => option['value'] === country)}
                                                    onChange={(selectedOption) => setCountry(selectedOption['label'])}
                                                    placeholder="Select Your Country"
                                                />
                                            </FormControl>
                                        </div>
                                    </div> */}
                                    {/* <div className='group-form'>
                                        <h6 className="text-left ml-3 mb-2">Add Card Option</h6>
                                        <Elements stripe={stripePromise}> <CheckoutForm /> </Elements>
                                    </div> */}
                                </div>
                                <div className="form-area">
                                    <p className='mt-3 bottom-text'>By purchasing this irl planter, you acknowledge that you have read and accept our <Link to="/TermsandCondition" target="_blanks">terms of services</Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>


            {/* ------------Congratulations MODAL------------ */}
            <Modal
                centered
                className='modal-login style-two congratulations-modal'
                isOpen={congratsModal}
                toggle={toggleCongratsModal}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalHeader><i className='icon'><img className='check-img' src={require('../../static/images/check.png')} alt='' /></i>Congratulations</ModalHeader>
                <ModalBody className='modal-body'>
                    <div className="modal-area tranding-sec">
                        <div className='tranding-cards'>
                            <Slider
                                {...modalSlider}>
                                <div className='item'>
                                    <div className='tranding-box'>
                                        <div className="flip-box">
                                            <div className="flip-box-front">
                                                <div className='img-box'>
                                                    <img className='front-img' src={planterData?.['frontImage']} alt='' />
                                                </div>
                                            </div>
                                            <div className="flip-box-back">
                                                <div className='img-box'>
                                                    <img className='back-img' src={planterData?.['backImage']} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>{`${planterData?.['name']} #${planterId}`}</h4>
                                            <div className='price-area'>
                                                <p>Quantity: <b>{quantity}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                        {/* <div className="check-area"> <img src={require('../../static/images/check.png')} alt='' /> </div> */}
                        <div className="detail-area">
                            <p>You will receive an email confirmation once your IRL Planter Kit(s) has shipped</p>
                            <p style={{ fontWeight: '400' }}>Please visit the the Profile Page to view your collections.</p>
                            <div className='row d-flex justify-content-center modal-confrom-btn'>
                                <button className="btn-style-one mr-3" onClick={() => { toggleCongratsModal(); setQuantity(1); }}>Close</button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div >
    );
}

export default IrlPlanters;