// import ReactGA from "react-ga4";
import EventBus from 'eventing-bus';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import { submitAllowList, setAllowListLoader } from "../../store/actions/Auth";
// import { toggleGoogleAnalytics, submitAllowList, setAllowListLoader } from "../../store/actions/Auth";
import { ConnectWallet, useSetIsWalletModalOpen, useAddress, darkTheme } from '@thirdweb-dev/react';

const ComingSoon = () => {
    const address = useAddress();
    const setIsWalletModalOpen = useSetIsWalletModalOpen();

    const dispatch = useDispatch();

    const { isAllowListLoader } = useSelector(st => st.Auth);
    // const { isGoogleAnalytics, isAllowListLoader } = useSelector(st => st.Auth);

    let [isCounter, setIsCounter] = useState(true);
    let [isSaleLive, setIsSaleLive] = useState(false);
    let [allowList, setAllowList] = useState({ firstName: '', email: '', publicAddress: '' });

    // useEffect(() => {
    //     if (isGoogleAnalytics) {
    //         // Google Analytics
    //         ReactGA.initialize('G-TSB0ZT5WXT');
    //         dispatch(toggleGoogleAnalytics(false));
    //     }
    // }, [isGoogleAnalytics]);

    useEffect(() => {
        if (!isAllowListLoader) setAllowList({ firstName: '', email: '', publicAddress: '' });
    }, [isAllowListLoader]);


    const handleChange = (e) => setAllowList({ ...allowList, [e.target.name]: e.target.value });
    const handleSubmit = () => {
        // if (!address) setIsWalletModalOpen(true);
        // else {
            if (allowList['firstName'] == '') return EventBus.publish('info', 'Please enter the First name');
            if (allowList['email'] == '') return EventBus.publish('info', 'Please enter the Email');
            allowList['publicAddress'] = address;

            dispatch(setAllowListLoader(true));
            dispatch(submitAllowList(allowList));
        // }
    };

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) return <h2>Coming Soon</h2>;
        else {
            return (
                <div className="countdown-timer-time">
                    <div className="counter-day counter-days">
                        <h5>{days ? days : '-'}</h5>
                        <p>Days</p>
                    </div>
                    <div className="counter-day counter-hour">
                        <h5>{hours ? hours : '-'}</h5>
                        <p>Hours</p>
                    </div>
                    <div className="counter-day counter-minute">
                        <h5>{minutes ? minutes : '-'}</h5>
                        <p>Min</p>
                    </div>
                    <div className="counter-day counter-seconds">
                        <h5>{seconds ? seconds : '-'}</h5>
                        <p>Sec</p>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className='cheeba-nft-collection-page'>
            <div>
                <section className='coming-soon-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='top-cutdown-area'>
                                    <div className='left-logo-area'>
                                        <a href="/">
                                            <img src={require('../../static/images/logo.png')} alt='' />
                                        </a>
                                        {/* <p>(Coming Soon)</p> */}
                                    </div>

                                    <div className='right-area'>
                                        {isCounter &&
                                            <Countdown date={Date.now() + parseInt((1713482400 * 1000) - Date.now())} renderer={renderer} onComplete={() => setIsCounter(false)} />
                                        }
                                        <ConnectWallet
                                            theme={darkTheme({
                                                colors: {
                                                    primaryButtonBg: "#008900",
                                                    primaryButtonText: "#ffffff",
                                                },
                                            })}
                                            modalSize={"wide"}
                                            switchToActiveChain={true}
                                            modalTitle={"CheebaPet NFT"}
                                        />
                                    </div>
                                </div>
                                <h2>CheebaPet Allowlist Signup</h2>
                                <h3>Signup to be Notified of the <br />Next Digital or IRL Drop</h3>
                                <div className='row row-center'>
                                    <div className='col-lg-3 col-md-12'>
                                        <div className='img-box text-left4'>
                                            <img src={require('../../static/images/nft-4.png')} alt='' />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className='singup-area'>

                                            <div className='form-box'>
                                                {/* <h3>Signup for Allowlist</h3> */}
                                                <form>
                                                    <div className="group-form">
                                                        <input
                                                            type="text"
                                                            placeholder='First name'
                                                            name="firstName"
                                                            value={allowList['firstName']}
                                                            required
                                                            onChange={(e) => handleChange(e)}
                                                        />
                                                    </div>
                                                    <div className="group-form">
                                                        <input
                                                            type="email"
                                                            placeholder='Email'
                                                            name="email"
                                                            value={allowList['email']}
                                                            required
                                                            onChange={(e) => handleChange(e)}
                                                        />
                                                    </div>
                                                    <div className="group-form">
                                                        <input
                                                            type="text"
                                                            placeholder='Wallet ID'
                                                            name="publicAddress"
                                                            value={address || ''}
                                                            onChange={(e) => handleChange(e)}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="group-form">
                                                        <button className='submit-btn' type="button" onClick={() => handleSubmit()}>Submit</button>
                                                    </div>
                                                </form>
                                                <p>By signing up, you agree to recieve company communications. View our <Link className="link-text" target="_blank" rel="noopener noreferrer" to="/PrivacyPolicy">Privacy Policy</Link> and <Link className="link-text" target="_blank" rel="noopener noreferrer" to="/TermsandCondition">Terms of Services</Link> for more information.</p>
                                            </div>
                                        </div>
                                        <div className='social-box'>
                                            <ul>
                                                <li><a className="social-link" href="https://www.facebook.com/CheebaPet" target="_blank"><img src={require('../../static/images/fb-icon.png')} alt='' /></a></li>
                                                <li><a className="social-link" href="https://discord.gg/DQ2bCazcDp" target="_blank"><img src={require('../../static/images/discord-icon.png')} alt='' /></a></li>
                                                <li><a className="social-link" href="https://twitter.com/CheebaPet" target="_blank"><img src={require('../../static/images/twitter-icon.png')} alt='' /></a></li>
                                                {/* <li><a className="social-link" href="https://www.instagram.com/cheebapet/" target="_blank"><img src={require('../../static/images/instagram-icon.png')} alt='' /></a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-12'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/heading-banner.png')} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div >
    );
}

export default ComingSoon;