import { useDispatch } from 'react-redux';
import React, { useState, useEffect, useRef } from "react";

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import Allowlist from '../../components/allowlist';

const Faqs = () => {

    const [sticky, setSticky] = useState(false);
    
    const onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) setSticky(true);
        if (pageYOffset < 20) setSticky(false);
    };


    return (
        <div className='cheeba-nft-collection-page' onWheel={onScroll}>
            <div>
            <Navbar sticky={sticky} />
                <section className="faqs-sec">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div class="sec-title">
                                    <h2>Frequently Asked Questions</h2>
                                </div>
                                <div id="accordion">
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                What is an NFT
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" class="collapse collapase-active-area show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body">
                                            An NFT, or non-fungible token, is a digital certificate of ownership for a unique digital asset.  Unlike fungible things like money, each NFT is one-of-a-kind and can't be replicated. NFTs are stored on a blockchain, a secure public ledger, which tracks ownership and verifies authenticity.  They can represent things like digital art, music, videos, or even real-world assets.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingTwo">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Why Buy NFTs
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" class="collapse collapase-active-area" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div class="card-body">
                                                <b>Investment:</b>  People hoping the NFT will increase in value and can be resold later for a profit. This is similar to how people might invest in stocks or real estate.<br/><br/>
                                                <b>Interest/Utility:</b>  Because they genuinely like the NFT, support the artist, or get perks like access to exclusive communities or events.  Owning an NFT can be a way to connect with a particular artist or community.  Some NFTs also function like digital club memberships, granting access to private chats, forums or events.<br/><br/>
                                                <b>Supporting Creators:</b>  NFTs can provide a new way for artists and creators to sell their work and directly connect with fans. By purchasing an NFT, you're not just buying a digital asset, you're also supporting the artist who created it..<br/><br/>
                                                <b>Collecting:</b>  NFTs can be seen as a new form of digital collectible.  Just like people collect physical items such as trading cards or rare coins, NFTs can be collected for their uniqueness and potential value.<br/><br/>
                                                <b>Status Symbol:</b>  Ownership of certain NFTs can be a status symbol, signaling that you're part of a particular community or that you have a sophisticated understanding of the digital art world.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                What is Blockchain
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" class="collapse collapase-active-area" aria-labelledby="headingThree" data-parent="#accordion">
                                            <div class="card-body">
                                                Blockchain is a decentralized, distributed ledger technology that enables secure and transparent transactions and interactions between parties, without the need for intermediaries such as banks or other financial institutions. In essence, it is a database or ledger that is shared across a network of computers or nodes, where each node has a copy of the ledger.<br /><br />
                                                The blockchain ledger records transactions in blocks that are linked together in a chain, hence the name "blockchain." Each block contains a unique digital signature, called a hash, that verifies the integrity of the data in the block. Once a block is added to the chain, it cannot be altered or deleted, which ensures the immutability of the ledger.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingfour">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                                What are Cryptocurrencies
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsefour" class="collapse collapase-active-area" aria-labelledby="headingfour" data-parent="#accordion">
                                            <div class="card-body">
                                                Cryptocurrencies are digital or virtual currencies that use cryptography for security and operate independently of a central bank or financial institution. Cryptocurrencies are typically decentralized, meaning that they are not controlled by any government or authority, and they use a distributed ledger, such as a blockchain, to record and verify transactions.<br /><br />
                                                The most well-known and widely used cryptocurrency is Bitcoin, which was created in 2009. Since then, many other cryptocurrencies have been created, such as Ethereum, Litecoin, and Ripple, among others. Each cryptocurrency has its own unique characteristics, features, and uses.<br /><br />
                                                Cryptocurrencies can be used for a variety of purposes, such as buying goods and services, transferring money, investing, and trading on cryptocurrency exchanges. Cryptocurrencies are usually bought and sold on cryptocurrency exchanges, where buyers and sellers can exchange them for other cryptocurrencies or for traditional fiat currencies, such as US dollars or euros.<br />
                                                Cryptocurrencies have been praised for their potential to provide greater financial freedom and privacy, as well as for their ability to facilitate fast and low-cost cross-border transactions.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingfive">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                                What is a Crypto Wallet
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsefive" class="collapse collapase-active-area" aria-labelledby="headingfive" data-parent="#accordion">
                                            <div class="card-body">
                                                A crypto wallet, also known as a cryptocurrency wallet or digital wallet, is a software program that allows users to securely store, send, and receive digital assets such as Bitcoin, Ethereum, or other cryptocurrencies. A crypto wallet generates and stores a user's private and public keys, which are used to securely access and manage their digital assets on a blockchain network.<br /><br />
                                                Crypto wallets come in different forms, including desktop, mobile, web-based, and hardware wallets. Desktop wallets are installed on a user's computer, while mobile wallets are installed on a user's smartphone. Web-based wallets can be accessed through a web browser, and hardware wallets are physical devices that store a user's private keys offline, providing an added layer of security.<br /><br />
                                                In addition to storing digital assets, crypto wallets allow users to view their transaction history, manage their digital assets, and interact with decentralized applications (DApps) on a blockchain network. Some wallets also offer additional features such as the ability to exchange or swap digital assets within the wallet interface, as well as access to decentralized finance (DeFi) protocols for lending, borrowing, and earning interest on crypto holdings.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingSix">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                Why do I need Crypto Wallet
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseSix" class="collapse collapase-active-area" aria-labelledby="headingSix" data-parent="#accordion">
                                            <div class="card-body">
                                                You need a crypto wallet if you want to participate in the world of cryptocurrency in any meaningful way. Here's why:
                                                <ul>
                                                    <li><b>Store Cryptocurrencies:</b>  A crypto wallet securely stores the digital keys you need to access and manage your crypto holdings on the blockchain.  Without a wallet, you can't hold any crypto.</li>

                                                    <li><b>Buy and Sell Crypto:</b>  If you want to buy or sell cryptocurrency or NFTs on exchanges or directly with others, you'll need a wallet to transfer the crypto in and out.</li>
                                                    <li><b>Send and Receive Crypto:</b>  Crypto wallets allow you to easily send and receive cryptocurrency and NFTs to and from others.</li>
                                                    <li><b>Interact with Blockchain Applications:</b>  Many blockchain applications (dApps) like NFT marketplaces or DeFi platforms require a crypto wallet to connect and interact with them.  The wallet stores your keys and authorizes transactions on the blockchain.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingSeven">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                What is Metamask
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseSeven" class="collapse collapase-active-area" aria-labelledby="headingSeven" data-parent="#accordion">
                                            <div class="card-body">
                                                MetaMask is a popular crypto wallet and gateway to the world of blockchain applications.  Here's a quick breakdown:
                                                <ul>
                                                    <li>Crypto Wallet: It functions like a digital wallet for your cryptocurrencies and tokens, allowing you to store, send, and receive them.</li>
                                                    <li>Blockchain Apps: It acts as a bridge, letting you connect securely to decentralized applications (dApps) built on the Ethereum blockchain. These dApps could be marketplaces for NFTs or DeFi (decentralized finance) platforms.</li>
                                                    <li>Browser Extension & Mobile App: It's available as a browser extension for Chrome, Firefox, etc., or as a mobile app for convenient access on your devices.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="card">
                                        <div class="card-header" id="headingEight">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                    Cheeba.World
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseEight" class="collapse collapase-active-area" aria-labelledby="headingEight" data-parent="#accordion">
                                            <div class="card-body">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div class="card">
                                        <div class="card-header" id="headingNne">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNne" aria-expanded="false" aria-controls="collapseNne">
                                                    CheebaCoin
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseNne" class="collapse collapase-active-area" aria-labelledby="headingNne" data-parent="#accordion">
                                            <div class="card-body">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <Allowlist /> */}
            </div>
            <Footer />

        </div >
    );
}

export default Faqs;