import React, { useState, useEffect, useRef, useLocation } from "react";

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

const RoadMap = () => {

    const [sticky, setSticky] = useState(false);
    
    const onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) setSticky(true);
        if (pageYOffset < 20) setSticky(false);
    };


    return (
        <div className='cheeba-nft-collection-page' onWheel={onScroll}>
            <div>
                <Navbar sticky={sticky} />
                <section className='roadmap-sec'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="roadmap-inner">
                                    <img src={require('../../static/images/roadmap-1.jpg')} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='session-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="session-title-area">
                                    <img src={require('../../static/images/session-heading.png')} alt='' />
                                </div>
                            </div>
                            <div className="col">
                                <div className="nft-box">
                                    <img src={require('../../static/images/rofmap-nft-1.png')} alt='' />
                                </div>
                            </div>
                            <div className="col">
                                <div className="nft-box">
                                    <img src={require('../../static/images/rofmap-nft-2.png')} alt='' />
                                </div>
                            </div>
                            <div className="col">
                                <div className="nft-box">
                                    <img src={require('../../static/images/rofmap-nft-3.png')} alt='' />
                                </div>
                            </div>
                            <div className="col">
                                <div className="nft-box">
                                    <img src={require('../../static/images/rofmap-nft-4.png')} alt='' />
                                </div>
                            </div>
                            <div className="col">
                                <div className="nft-box">
                                    <img src={require('../../static/images/rofmap-nft-5.png')} alt='' />
                                </div>
                            </div>
                            <div className="col">
                                <div className="nft-box">
                                    <img src={require('../../static/images/rofmap-nft-6.png')} alt='' />
                                </div>
                            </div>
                            <div className="col">
                                <div className="nft-box">
                                    <img src={require('../../static/images/rofmap-nft-7.png')} alt='' />
                                </div>
                            </div>
                            <div className="col">
                                <div className="nft-box">
                                    <img src={require('../../static/images/rofmap-nft-8.png')} alt='' />
                                </div>
                            </div>
                            <div className="col">
                                <div className="nft-box">
                                    <img src={require('../../static/images/rofmap-nft-9.png')} alt='' />
                                </div>
                            </div>
                            <div className="col">
                                <div className="nft-box">
                                    <img src={require('../../static/images/rofmap-nft-10.png')} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className='roadmap-sec'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="roadmap-inner">
                                    <img src={require('../../static/images/roadmap-2.jpg')} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="nft-section">
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="session-title-area">
                                    <img src={require('../../static/images/nfts-heading.png')} alt='' />
                                    <img className="sec-heading" src={require('../../static/images/nfts-heading-2.png')} alt='' />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="crypt-box">
                                    <img src={require('../../static/images/about-banner-1.png')} alt='' />
                                    <h4>4/20</h4>
                                    <h5>NFT Trading Cards</h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="crypt-box">
                                    <img src={require('../../static/images/about-banner-3.png')} alt='' />
                                    <h4>Date TBD</h4>
                                    <h5>NFT CheebaPet Planters</h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="crypt-box">
                                    <img src={require('../../static/images/about-banner-4.png')} alt='' />
                                    <h4>Date TBD</h4>
                                    <h5>NFT OG Collection</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='crypto-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="session-title-area">
                                    <img src={require('../../static/images/crypto-title.png')} alt='' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="crypt-box">
                                    <img src={require('../../static/images/cheeba-coin-1.png')} alt='' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="crypt-box">
                                    <img src={require('../../static/images/cheeba-coin-2.png')} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='roadmap-sec'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="roadmap-inner">
                                    <img src={require('../../static/images/roadmap-4.jpg')} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='roadmap-sec style-two'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="roadmap-inner">
                                    <img src={require('../../static/images/roadmap-5.jpg')} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='roadmap-sec'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="roadmap-inner">
                                    <img src={require('../../static/images/roadmap-6.jpg')} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='roadmap-timeline-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="timeline-inner">
                                    <img src={require('../../static/images/timeline-bg.png')} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='button-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <a className="btn-style-three" href="https://cheebapet.com/" target="_blank">Visit CheebaPet.com</a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />

        </div >
    );
}

export default RoadMap;