import axios from 'axios';
import { utils } from 'ethers';
import EventBus from 'eventing-bus';
import { Link } from 'react-router-dom';
import { validate } from 'email-validator';
import Checkbox from '@mui/material/Checkbox';
// import { loadStripe } from '@stripe/stripe-js';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { useAddress, Web3Button, useBalance } from '@thirdweb-dev/react';
// import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

import 'animate.css';
import 'react-toastify/dist/ReactToastify.css';
import { opensea } from '../../store/config';
// import { stripeKey, opensea } from '../../store/config';
import { getMetadata, setBoughtNFT, setCartItems, setShopify } from '../../store/actions/NFT';
import { toggleLoader, toggleReviewCartModal, toggleBuyNFTModal, setisCartNFTs } from '../../store/actions/Auth';

const Modals = () => {
    const address = useAddress();
    const dispatch = useDispatch();
    const { data: balance } = useBalance();
    // const [stripePromise] = useState(loadStripe(stripeKey));

    let nftId = window.location.href.split('#')[1];
    const isProductPage = window.location.pathname === '/NftTradingCard';

    let { reviewCartModal, buyNFTModal, isCartNFTs } = useSelector(st => st.Auth);
    let { CrowdSaleAddress, currentSalePrice, cartItems, nftMetadata, shopifyOrder, boughtNFT, TokenAddress } = useSelector(st => st.NFT);

    let [buyType, setBuyType] = useState('ETH');
    let [nftQuantity, setNFTQuantity] = useState(1);
    let [shippedNFT, setShippedNFT] = useState(false);
    let [shippingEmail, setShippingEmail] = useState('');

    const calculatePrice = () => {
        let price, tokenIds = [], quantities = [];
        const adjustShippedNftId = (id) => (Number(id) > 10 ? Number(id) : Number(id) + 10);
        const adjustVaultedNftId = (id) => (Number(id) > 10 ? Number(id) - 10 : Number(id));

        if (isCartNFTs) {
            let totalQuanity;
            Object.values(cartItems).map(item => {
                item['nftId'] = shippedNFT ? adjustShippedNftId(item['nftId']) : adjustVaultedNftId(item['nftId']);
                tokenIds.push(item['nftId']);
                quantities.push(Number(item['quantity']));
            });
            totalQuanity = quantities.reduce((a, b) => Number(a) + Number(b));
            price = (currentSalePrice * totalQuanity);
        } else {
            let nftID = shippedNFT ? adjustShippedNftId(nftId) : adjustVaultedNftId(nftId);
            tokenIds.push(nftID);
            quantities.push(Number(nftQuantity));
            price = (currentSalePrice * nftQuantity);
        }
        return { price, tokenIds, quantities };
    };

    const handleBuyClick = async (crowdSale) => {
        try {
            let { price, tokenIds, quantities } = calculatePrice();
            if (price > balance['displayValue']) return EventBus.publish('error', 'You have insufficient balance!');
            else if (shippedNFT) {
                if (!shippingEmail) return EventBus.publish('error', `Please provide email address`);
                else if (!validate(shippingEmail)) return EventBus.publish('error', `Please provide valid email address`);
            }

            const overrides = { value: utils.parseEther(String(price)) };
            let transaction = await crowdSale.call("buyTokens", [tokenIds, quantities, address], overrides);

            handleRemoveAllCart();
            if (isProductPage) dispatch(getMetadata({ nftId }));
            dispatch(toggleBuyNFTModal(false));
            dispatch(setBoughtNFT({ nftId, price, nftMetadata, shippedNFT, tokenIds, quantities, shippingEmail, transaction, buyType }));

            return EventBus.publish('success', `${isCartNFTs ? "Cart Items" : nftMetadata?.['name']} Purchased Sucessfully.`);
        } catch (error) { return EventBus.publish('error', `Failed to buy ${nftMetadata?.['name'] || 'NFT please refresh and try again'}.`); }
    };

    const handleCartQuantity = async (e, item) => {
        if (e['target']['value'] <= 0) return EventBus.publish('info', 'Quantity should be greater then 0');
        if (cartItems[item?.['name']]) cartItems[item['name']]['quantity'] = e['target']['value'];
        dispatch(setCartItems(cartItems));
    };

    const handleRemoveCart = async (item) => {
        delete cartItems[item['name']];
        dispatch(setCartItems(cartItems));
        if (cartItems.length == 0) dispatch(toggleReviewCartModal(false));
    };

    const handleRemoveAllCart = async () => {
        dispatch(setCartItems([]));
        dispatch(toggleReviewCartModal(false));
    };

    const handleCartContinue = () => {
        dispatch(setisCartNFTs(true));
        setTimeout(() => dispatch(toggleReviewCartModal(false)), 100);
        setTimeout(() => dispatch(toggleBuyNFTModal(true)), 500);
    };

    // const CheckoutForm = () => {
    //     const stripe = useStripe();
    //     const elements = useElements();
    //     const handleSubmit = async () => {
    //         try {
    //             if (shippedNFT) {
    //                 if (!shippingEmail) return EventBus.publish('error', `Please provide email address`);
    //                 else if (!validate(shippingEmail)) return EventBus.publish('error', `Please provide valid email address`);
    //             }

    //             const { error, paymentMethod } = await stripe.createPaymentMethod({ type: "card", card: elements.getElement(CardElement) });
    //             if (error) return console.log(error['message']);

    //             dispatch(toggleBuyNFTModal(false));
    //             dispatch(toggleLoader(true));
    //             submitPayment(paymentMethod['id']);
    //         } catch (e) { console.log('*******e = ', e); }
    //     }
    //     const submitPayment = async (methodId) => {
    //         try {
    //             let { price, tokenIds, quantities } = calculatePrice();
    //             let { data } = await axios.post('/nft/stripeIntent', { methodId, price, shippedNFT, tokenIds, quantities, TokenAddress, CrowdSaleAddress, shippingEmail });
    //             if (!data['body']) {
    //                 EventBus.publish('error', data['message']);
    //                 dispatch(toggleLoader(false));
    //                 return;
    //             }
    //             const transaction = await stripe?.confirmCardPayment(data['body']);
    //             if (transaction?.['error']) {
    //                 EventBus.publish('error', transaction['error']['message']);
    //                 dispatch(toggleLoader(false));
    //                 return;
    //             }

    //             handleRemoveAllCart();
    //             if (isProductPage) dispatch(getMetadata({ nftId }));
    //             dispatch(setBoughtNFT({ nftId, price, nftMetadata, shippedNFT, tokenIds, quantities, shippingEmail, transaction, buyType }));

    //             dispatch(toggleLoader(false));
    //             return EventBus.publish('success', `${isCartNFTs ? "Cart Items" : nftMetadata?.['name']} Purchased Sucessfully.`);
    //         } catch (e) {
    //             dispatch(toggleLoader(false));
    //             console.log('****** e = ', e);
    //         }
    //     }

    //     const CARD_ELEMENT_OPTIONS = {
    //         style: {
    //             base: {
    //                 fontSize: "18px",
    //                 color: "#32325d",
    //                 fontSmoothing: "antialiased",
    //                 "::placeholder": { color: "#aab7c4" },
    //                 fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    //             }, invalid: { color: "#fa755a", iconColor: "#fa755a" }
    //         }
    //     }
    //     return (
    //         <>
    //             <div className='my-4'>
    //                 <CardElement options={CARD_ELEMENT_OPTIONS} />
    //             </div>
    //             <div className="group-form style-three" >
    //                 <button disabled={!stripe || !elements} onClick={() => handleSubmit()} className="card-btn">{`Buy Now`}</button>
    //             </div>
    //         </>
    //     );
    // };

    return (
        <div className='modals'>
            {/* -----------------BUY NFT MODAL----------------- */}
            <Modal
                isOpen={buyNFTModal}
                className='modal-login style-two'
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <ModalHeader toggle={() => dispatch(toggleBuyNFTModal(false))}>Buy NFT</ModalHeader>
                <ModalBody className='modal-body'>
                    <div className="modal-area">
                        <table class="table">
                            <tbody>
                                {isCartNFTs
                                    ? <>{Object.values(cartItems).map(item => {
                                        return (
                                            <tr>
                                                <td className="buy-nft">
                                                    <Checkbox checked={true} icon={<CircleUnchecked />} checkedIcon={<CircleChecked />} />
                                                </td>
                                                <td>
                                                    <div className="about-game-img">
                                                        {item?.['front'] ? <img src={item['front']} alt='' /> : <img src={require('../../static/images/product-detail-1.png')} alt='' />}
                                                    </div>
                                                </td>
                                                <td><label for="option1">{item?.['name']} #{item?.['nftId']}</label></td>
                                                <td><input onChange={(e) => handleCartQuantity(e, item)} name="number" type="number" value={item['quantity']} /></td>
                                                <td>{(currentSalePrice * item['quantity']).toFixed(4)} ETH</td>
                                            </tr>
                                        )
                                    })}</>
                                    : <tr>
                                        <td className="buy-nft">
                                            <Checkbox checked={true} icon={<CircleUnchecked />} checkedIcon={<CircleChecked />} />
                                        </td>
                                        <td>
                                            <div className="about-game-img">
                                                {nftMetadata['front'] ? <img src={nftMetadata['front']} alt='' /> : <img src={require('../../static/images/product-detail-1.png')} alt='' />}
                                            </div>
                                        </td>
                                        <td><label for="option1">{nftMetadata['name']} #{nftId}</label></td>
                                        <td><input onChange={(e) => setNFTQuantity(e.target.value)} name="number" type="number" value={nftQuantity} /></td>
                                        <td>{(currentSalePrice * nftQuantity).toFixed(4)} ETH</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className="form-area">
                            <div className="group-form style-two">
                                <span className="icon"><img src={require('../../static/images/valuted-icon.png')} alt='' /></span>
                                {/* <label class="switch"><input value={shippedNFT} onChange={(e) => setShippedNFT(e.target.checked)} type="checkbox" /><span class="slider round"></span></label> */}
                                {/* <span className="icon style-two"><img src={require('../../static/images/shiped-nft.png')} alt='' /></span> */}
                            </div>
                            <div className="group-form style-three mt-3">
                                <div className="group-form style-four">
                                    <h4>Buy with</h4>
                                    <div className="radio-eth-area">
                                        <ul class="nav nav-tabs">
                                            <li class="nav-item">
                                                <a onClick={() => setBuyType('ETH')} class={`nav-link ${buyType == 'ETH' && ' active'}`}>
                                                    <p>Crypto</p>
                                                    <span className="icon"><img src={require('../../static/images/eth-icon.png')} alt='' /></span>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a onClick={() => setBuyType('Card')} class={`nav-link ${buyType == 'Card' && ' active'}`}>
                                                    <p>Credit Card</p>
                                                    <span className="icon"><img src={require('../../static/images/card.png')} alt='' /></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {shippedNFT && <>
                                    <label>Please Enter Your Email</label>
                                    <input onChange={(e) => setShippingEmail(e.target.value)} value={shippingEmail} type="email" placeholder="Enter your email" />
                                </>}
                                <div class="tab-content">
                                    {buyType == 'ETH' ?
                                        <>
                                            <div className="group-form style-three">
                                                <label>Your Wallet Address</label>
                                                <input type="text" value={address} disabled={true} style={{ "color": "grey" }} />
                                            </div>
                                            <div className="group-form style-three" style={{ "paddingTop": "10px" }} >
                                                <Web3Button contractAddress={CrowdSaleAddress} action={(contract) => handleBuyClick(contract)}>{`Buy Now`}</Web3Button>
                                            </div>
                                        </> : <p class='text-center text-info mt-3'>Stripe is not working currently</p>
                                        // </> : <Elements stripe={stripePromise}> <CheckoutForm /> </Elements>
                                    }
                                </div>
                            </div>
                            <p className='mt-3 bottom-text'>By purchasing this card, you acknowledge that you have read and accept our terms of services</p>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* ---------------ADD TO CART MODAL--------------- */}
            <Modal
                isOpen={reviewCartModal}
                className='modal-login style-two'
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <ModalHeader toggle={() => dispatch(toggleReviewCartModal(false))}>Add to cart</ModalHeader>
                <ModalBody className='modal-body'>
                    <div className="modal-area">
                        <div className="top-area">
                            <div className="right-area">
                                {cartItems && Object.values(cartItems).length > 0 && <button onClick={() => handleRemoveAllCart()} className="select-area">Remove all</button>}
                            </div>
                        </div>
                        <table class="table">
                            <tbody>
                                {cartItems && Object.values(cartItems).length > 0
                                    ? <>{Object.values(cartItems).map(item => {
                                        return (
                                            <tr className="active">
                                                <td>
                                                    <div className="about-game-img">
                                                        {item?.['front']
                                                            ? <img src={item['front']} alt='' />
                                                            : <img src={require('../../static/images/product-detail-1.png')} alt='' />
                                                        }
                                                    </div>
                                                </td>
                                                <td><label for="option1">{`${item?.['name']} #${item?.['nftId']}`}</label></td>
                                                <td><input onChange={(e) => handleCartQuantity(e, item)} name="number" type="number" defaultValue={item['quantity']} /></td>
                                                <td>
                                                    <span className="text-price">{(currentSalePrice * item['quantity']).toFixed(4)} ETH</span>
                                                    <button onClick={() => handleRemoveCart(item)} className="remove-btn"><img src={require('../../static/images/remove-icon.png')} alt='' /></button>
                                                </td>
                                            </tr>
                                        )
                                    })}</>
                                    : <div className="no-nft-card">
                                        <i className="icon"><img src={require('../../static/images/no-item.png')} alt='' /></i>
                                        <h4>No NFT added to cart!</h4>
                                    </div>
                                }
                            </tbody>
                        </table>
                        {cartItems && Object.values(cartItems).length > 0 &&
                            <div className="detail-area">
                                <button onClick={() => handleCartContinue()} className="btn-style-one">Continue</button>
                            </div>
                        }
                    </div>
                </ModalBody>
            </Modal>

            {/* ------------CONFIRMATION ORER MODAL------------ */}
            <Modal
                centered
                className='modal-login style-two'
                isOpen={boughtNFT?.['nftId'] ? true : false}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalHeader> NFT Confirmation </ModalHeader>
                <ModalBody className='modal-body'>
                    <div className="modal-area">
                        <div className="check-area"> <img src={require('../../static/images/check.png')} alt='' /> </div>
                        <div className="detail-area">
                            <h4>Order Confirmed!</h4>
                            <p> You have bought NFT successfully! </p>
                            <p>Please Visit the
                                <Link to="/Rewards" >
                                    <span onClick={() => dispatch(setBoughtNFT({}))}> Rewards Page</span>
                                </Link> to Claim Your Free Planter Kit.</p>
                            {boughtNFT?.['shippedNFT'] && <p>Check your Email our team will inquire through email and NFT will be delivered within 3 to 5 business days.</p>}
                            <div className='row d-flex justify-content-center modal-confrom-btn'>
                                <button className="btn-style-one mr-3" onClick={() => setTimeout(() => { dispatch(setShopify({})); dispatch(setBoughtNFT({})); }, 1000)}>Close</button>
                                {shopifyOrder?.['type'] == 'Shipped' && <a className="btn-style-two mr-3" target="_blank" href={shopifyOrder['shopifyOrderLink']}><i className='icon'><img src={require('../../static/images/shopify-icon.png')} alt='' /></i>View Order</a>}
                                {boughtNFT?.['buyType'] == 'ETH' && <a className="btn-style-two" target="_blank" href={`${opensea}/${TokenAddress}/${boughtNFT?.['tokenIds']?.[0]}`}><i className='icon'><img src={require('../../static/images/opensea-icon.png')} alt='' /></i>View NFT</a>}
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </div>
    );
}

export default Modals;