import React, { useState } from "react";
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { useAddress } from '@thirdweb-dev/react';

import tradingCards from '../../store/tradingCards.json';

const NFTData = ({ page }) => {
    const address = useAddress();

    let nftId = window.location.href.split('#')[1];
    let { currentSalePrice } = useSelector(st => st.NFT);

    const [playVideo, setPlayVideo] = useState();

    const settingsNFT = {
        loop: true,
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        autoplaySpeed: 2000,
        responsive: [
            { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
            { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3, infinite: true } },
            { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 } },
        ]
    };


    return (
        <>{page == "home"
            ? <>{tradingCards.map((nft, index) => {
                if (nft['show'])
                    return (
                        <div className="col" key={index}>
                            <div className={`tranding-box `}>
                                <a href={`/NftTradingCard#${nft['tokenId']}`}>
                                    <div className='flip-box'>
                                        {playVideo == index
                                            ? <div className="about-game-img">
                                                {nft['animation_url'] &&
                                                    <>
                                                        <video id="video" controls loop autoPlay>
                                                            <source src={nft['animation_url']} type="video/mp4" />
                                                        </video>
                                                    </>
                                                }
                                            </div>
                                            : <>
                                                <div class="flip-box-front">
                                                    <div className='img-box'>
                                                        <img src={nft['frontImage']} alt='' />
                                                    </div>
                                                </div>
                                                <div class="flip-box-back">
                                                    <div className='img-box'>
                                                        <img src={nft['backImage']} alt='' />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </a>
                                <div className='text-box'>
                                    {playVideo == index
                                        ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                        : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                                    }
                                    <a href={`/NftTradingCard#${nft['tokenId']}`}>
                                        <h4>{nft['name']}</h4>
                                        <div className='price-area'>
                                            <p>Price: <b>{currentSalePrice} ETH</b></p>
                                            <a className='buy-btn' href={`/NftTradingCard#${nft['tokenId']}`}>Buy</a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </div>
                    )
            })}</>
            : <Slider {...settingsNFT}>
                {tradingCards.map((nft, index) => {
                    if (nftId && (nftId == nft['tokenId'])) return;
                    return (
                        <div key={index}>
                            <div className='tranding-box slider-box'>
                                <div className='flip-box'>
                                    {playVideo == index
                                        ? <div className="about-game-img">
                                            {nft['animation_url'] &&
                                                <video id="video" controls loop autoPlay muted>
                                                    <source src={nft['animation_url']} type="video/mp4" />
                                                </video>
                                            }
                                        </div>
                                        : <>
                                            <div class="flip-box-front">
                                                <div className='img-box'>
                                                    <img src={nft['frontImage']} alt='' />
                                                </div>
                                            </div>
                                            <div class="flip-box-back">
                                                <div className='img-box'>
                                                    <img src={nft['backImage']} alt='' />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='text-box'>
                                    {playVideo == index
                                        ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                        : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                                    }
                                    <h4>{nft['name']}</h4>
                                    <div className='price-area'>
                                        <p>Price: <b>{currentSalePrice} ETH</b></p>
                                        <a onClick={() => setTimeout(() => window.location.reload(), 100)} className='buy-btn' href={`/NftTradingCard#${nft['tokenId']}`}>Buy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        } </>
    );
}

export default NFTData;