import Slider from 'react-slick';
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

const IrlPlanterProducts = ({ page }) => {

    let tradingCardId = window.location.href.split('#')[1];
    const { irlPlantersJSON } = useSelector(st => st.NFT);

    const settingsTradingCards = {
        loop: true,
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        autoplaySpeed: 2000,
        responsive: [
            { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
            { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3, infinite: true } },
            { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 } },
        ]
    };

    return (
        <>{page == "home" ? <>
            <div className='row'>
                {irlPlantersJSON && irlPlantersJSON?.map((irlPlanter, index) => {
                    if (irlPlanter['show']) {
                        return (
                            <div className="col" key={index}>
                                <a href={`/IrlPlanters#${irlPlanter?.['tokenId']}`}>
                                    <div className='tranding-box'>
                                        <div className='flip-box'>
                                            <div className='flip-box-front'>
                                                <div className='img-box'>
                                                    <img src={irlPlanter?.['frontImage']} alt='' />
                                                </div>
                                            </div>
                                            <div className='flip-box-back'>
                                                <div className='img-box'>
                                                    <img src={irlPlanter?.['backImage']} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h4>{`${irlPlanter?.['name']} #${irlPlanter?.['tokenId']}`}</h4>
                                            <div className='price-area'>
                                                <p>Price: <b>${irlPlanter?.['price']}</b></p>
                                                <a className='buy-btn' href={`/IrlPlanters#${irlPlanter?.['tokenId']}`}>Buy</a>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    }
                })}
            </div>
        </> : <>
            <Slider {...settingsTradingCards}>
                {irlPlantersJSON && irlPlantersJSON?.map((irlPlanter, index) => {
                    if (tradingCardId && (tradingCardId == irlPlanter?.['tokenId'])) return;
                    return (
                        <div key={index}>
                            <div className='tranding-box slider-box'>
                                <div className='flip-box'>
                                    <div class="flip-box-front">
                                        <div className='img-box'>
                                            <img src={irlPlanter?.['frontImage']} alt='' />
                                        </div>
                                    </div>
                                    <div class="flip-box-back">
                                        <div className='img-box'>
                                            <img src={irlPlanter?.['backImage']} alt='' />
                                        </div>
                                    </div>
                                </div>
                                <div className='text-box'>
                                    <h4>{`${irlPlanter?.['name']} #${irlPlanter?.['tokenId']}`}</h4>
                                    <div className='price-area'>
                                        <p>Price: <b>{irlPlanter?.['price']} ETH</b></p>
                                        <a onClick={() => setTimeout(() => window.location.reload(), 100)} className='buy-btn' href={`/IrlPlanters#${irlPlanter?.['tokenId']}`}>Buy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </>
        }</>
    )
}
export default IrlPlanterProducts;