import './index.css';

import { Link } from 'react-router-dom';
import React, { Component } from 'react';

class Footer extends Component {

    handleScroll = () => {
        if (window.scrollY > 20) this.setState({ isScroll: true });
        else this.setState({ isScroll: false })
      };

    render() {
        return (
            <div>
                <div className='footer-cheeba-nft-collection' id='footer-main'>
                    <div className="auto-container">
                        <div className="row">
                           <div className='col-lg-2 col-md-4 col-sm-6'>
                                <div className='footer-links'>
                                    <ul>
                                        <li><Link className='footer-link' to="/">Home</Link></li>
                                        <li><Link className='footer-link' to="/RoadMap">Roadmap</Link></li>
                                    </ul>
                                    
                                </div>
                           </div>
                           <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='footer-links'>
                                <ul>
                                    <li><Link className='footer-link' to="/TradingCards">Trading Cards</Link></li>
                                    <li><Link className='footer-link' to="/Planters">Planters</Link></li>
                                    {/* <li>Address</li> */}
                                    {/* <li><Link className='footer-link' to="#">contact@cheebapet.com</Link></li> */}
                                </ul>
                            </div>
                           </div>
                           <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='footer-links'>
                                <ul>
                                    <li><Link className='footer-link' to="#">CheebaCoin</Link></li>
                                    <li><a className='footer-link' href="https://cheeba.world/" target='_blank'>Cheeba.World</a></li>
                                </ul>
                            </div>
                           </div>

                           <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='footer-links'>
                                <ul>
                                    <li><Link className='footer-link' to="#">Blog</Link></li>
                                    <li><Link className='footer-link' to="/Faqs">FAQ's</Link></li>
                                </ul>
                            </div>
                           </div>

                           <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='footer-links'>
                                <ul>
                                    <li><Link className='footer-link' to="/PrivacyPolicy" target='_blank'>Privacy</Link></li>
                                    <li><Link className='footer-link' to="/TermsandCondition" target='_blank'>Terms of Service</Link></li>
                                </ul>
                            </div>
                           </div>
                           <div className='col-lg-2 col-md-4 col-sm-6'>
                                <div className='social-link'>
                                    <ul>
                                    <li><a className="footer-link" href="https://www.facebook.com/CheebaPet" target="_blank"><img src={require('../../static/images/fb-icon.png')} alt='' /></a></li>
                                        <li><a className="footer-link" href="https://discord.gg/DQ2bCazcDp" target="_blank"><img src={require('../../static/images/discord-icon.png')} alt='' /></a></li>
                                        <li><a className="footer-link" href="https://twitter.com/CheebaPet" target="_blank"><img src={require('../../static/images/twitter-icon.png')} alt='' /></a></li>
                                        {/* <li><a className="footer-link" href="https://www.instagram.com/cheebapet/" target="_blank"><img src={require('../../static/images/instagram-icon.png')} alt='' /></a></li> */}
                                    </ul>
                                </div>
                           </div>
                        </div>
                        <div className='col-12'>
                            <div className='bottom-footer'>
                                <div className='row'>
                                    <div className='col-lg-7 col-md-6 col-sm-12'>
                                        <div className='copyright-text'>
                                            <p>&copy; 2024, CheebaPet, Inc.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-md-6 col-sm-12'>
                                        <div className='content-area'>
                                            <a href='mailto:contact@cheebapet.com'>contact@cheebapet.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whatsapp-swipe">
                    <Link className='bottom-icon' to="#">
                        <img src={require('../../static/images/massage-icon.png')} alt='' />
                    </Link>
                </div>
            </div>
        );
    }
}

export default Footer;