import EventBus from 'eventing-bus';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import 'animate.css';
import './index.css';
import '../../static/css/animate.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { getMyIrlPlanters } from '../../store/actions/NFT';
import { toggleLoader, getUserProfile, uploadProfilePicture } from '../../store/actions/Auth';
import { object } from 'prop-types';

const Profile = () => {
    const dispatch = useDispatch();
    const [sticky, setSticky] = useState(false);
    let { userProfile, userProfileImage } = useSelector(st => st.Auth);
    let { irlPlantersJSON, myIrlPlanters } = useSelector(st => st.NFT);

    useEffect(() => {
        dispatch(toggleLoader(true));
        dispatch(getUserProfile());
        dispatch(getMyIrlPlanters());
    }, []);

    const handleProfileFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            let reqData = new FormData();
            reqData.append('profilePicture', file);
            dispatch(uploadProfilePicture(reqData));
        }
    };

    const onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) setSticky(true);
        if (pageYOffset < 20) setSticky(false);
    };

    return (
        <div className='cheeba-nft-collection-page' onWheel={onScroll}>
            <div className="cheeba-rewards-page">
                <Navbar sticky={sticky} />
                <section className="banner-porfile-area">
                    <div className="container-fluid">
                        <div className="row"> </div>
                    </div>
                </section>
                <section className="porfile-sec">
                    <div className="auto-container">
                        <div className='row'>
                            <div className="col-12">
                                <div className="profile-area">
                                    <div className="img-box">
                                        {userProfileImage ? <img src={userProfileImage} alt='' /> : userProfile?.['profilePicture'] ? <img src={userProfile['profilePicture']} alt='' /> : <img src={require('../../static/images/profile-img.png')} alt='' />}
                                        <div className='input-file'>
                                            <input
                                                className=''
                                                accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                type='file'
                                                id='upload'
                                                onChange={handleProfileFileChange}
                                            />
                                            <div className='content-btn'>
                                                <button><img src={require('../../static/images/edit-profile.png')} alt='' /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-box">
                                        <div className='heading-area'>
                                            {userProfile?.['username'] && <>
                                                <h4>{userProfile['username']}</h4>
                                                <button><img className='back-img' src={require('../../static/images/edit-pencil.png')} alt='' /></button>
                                            </>}
                                        </div>
                                        <div className="text-eth">
                                            {userProfile?.['publicAddress'] &&
                                                <>
                                                    <span className="icon"><img src={require('../../static/images/eth-icon.png')} alt='' /></span>
                                                    <CopyToClipboard text={userProfile['publicAddress']}><button onClick={() => EventBus.publish('info', "Address Copy to Clipboard")}><p>{userProfile['publicAddress']}</p><i className="icon"><img src={require('../../static/images/copy.png')} alt='' /></i></button></CopyToClipboard>
                                                </>
                                            }
                                        </div>
                                        {/* {userProfile?.['createdAt'] && <p className="text-box">{moment(userProfile['createdAt']).format('lll')}</p>} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="tranding-sec profile-page-cards">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="items-sec">
                                    {userProfile?.['metadataNFT'].length > 0 &&
                                        <div class="tab-content" id="myTabContent">
                                            <h2>NFT Trading Cards - OG Collection</h2>
                                            <div class="tab-pane fade show active" id="collected" role="tabpanel" aria-labelledby="collected-tab">
                                                {userProfile && userProfile?.['metadataNFT'] && userProfile?.['metadataNFT'].length === 0 ?
                                                    <>
                                                        <h4>No items</h4>
                                                        <div className='tranding-cards'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='no-item'>
                                                                        <h4>No item founds</h4>
                                                                        <i className="icon"><img src={require('../../static/images/not-found.png')} alt='' /></i>
                                                                        {/* <Link className="btn-style-one" to="#">Back to all items</Link> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <>
                                                        <h4>
                                                            {userProfile?.['metadataNFT'] ?
                                                                `${userProfile?.['metadataNFT'].length} ${userProfile?.['metadataNFT'].length > 1 ? 'Items' : 'Item'}`
                                                                :
                                                                '0 Items'}
                                                        </h4>
                                                        <div className='tranding-cards'>
                                                            <div className='row'>
                                                                {userProfile?.['metadataNFT'] && userProfile?.['metadataNFT'].map((nftData, index) => (
                                                                    <div className='col'>
                                                                        <div className='tranding-box' key={index}>
                                                                            <div className="flip-box">
                                                                                {nftData?.['front']
                                                                                    ? <div className="flip-box-front">
                                                                                        <div className='img-box'>
                                                                                            <img className='front-img' src={nftData['front']} alt='' />
                                                                                        </div>
                                                                                    </div>
                                                                                    : <div className="flip-box-front">
                                                                                        <div className='img-box'>
                                                                                            <img className='front-img' src={require('../../static/images/trading-card-1-front.png')} alt='' />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {nftData?.['back']
                                                                                    ? <div className="flip-box-back">
                                                                                        <div className='img-box'>
                                                                                            <img className='back-img' src={nftData['back']} alt='' />
                                                                                        </div>
                                                                                    </div>
                                                                                    : <div className="flip-box-back">
                                                                                        <div className='img-box'>
                                                                                            <img className='back-img' src={require('../../static/images/trading-card-1-back.png')} alt='' />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div className='text-box'>
                                                                                <h4>{`${nftData['name']} #${nftData['nftID']} - ${nftData['nftID'] < 11 ? 'Vaulted' : 'Shipped'}`}</h4>
                                                                                <div className='price-area'>
                                                                                    <p>Quantity: <b>{nftData['nftQuantity']}</b></p>
                                                                                    <Link className='buy-btn' to={`/NftTradingCard#${nftData['nftID']}`}>View</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {Object.values(myIrlPlanters).length > 0 &&
                                        <div class="tab-content" id="myTabContent">
                                            <h2>IRL Planters Kits – Alpha Collection</h2>
                                            <div class="tab-pane fade show active" id="collected" role="tabpanel" aria-labelledby="collected-tab">
                                                {userProfile && irlPlantersJSON && Object.values(myIrlPlanters).length === 0 ?
                                                    <>
                                                        <h4>No items</h4>
                                                        <div className='tranding-cards'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='no-item'>
                                                                        <h4>No item founds</h4>
                                                                        <i className="icon"><img src={require('../../static/images/not-found.png')} alt='' /></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <>
                                                        <h4>
                                                            {Object.values(myIrlPlanters).length > 0 ?
                                                                `${Object.values(myIrlPlanters).length} ${Object.values(myIrlPlanters).length > 1 ? 'Items' : 'Item'}`
                                                                :
                                                                '0 Items'}
                                                        </h4>
                                                        <div className='tranding-cards'>
                                                            <div className='row'>
                                                                {Object.values(myIrlPlanters).length > 0 && Object.entries(myIrlPlanters).map(reward => (
                                                                    <div className='col' index={reward[0]}>
                                                                        <div className='tranding-box'>
                                                                            <div className="flip-box">
                                                                                {irlPlantersJSON?.[Number(reward[0]) - 1]?.['frontImage']
                                                                                    ? <div className="flip-box-front">
                                                                                        <div className='img-box'>
                                                                                            <img className='front-img' src={irlPlantersJSON[Number(reward[0]) - 1]['frontImage']} alt='' />
                                                                                        </div>
                                                                                    </div>
                                                                                    : <div className="flip-box-front">
                                                                                        <div className='img-box'>
                                                                                            <img className='front-img' src={require('../../static/images/trading-card-1-front.png')} alt='' />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {irlPlantersJSON?.[Number(reward[0]) - 1]?.['backImage']

                                                                                    ? <div className="flip-box-back">
                                                                                        <div className='img-box'>
                                                                                            <img className='back-img' src={irlPlantersJSON[Number(reward[0]) - 1]['backImage']} alt='' />
                                                                                        </div>
                                                                                    </div>
                                                                                    : <div className="flip-box-back">
                                                                                        <div className='img-box'>
                                                                                            <img className='back-img' src={require('../../static/images/trading-card-1-back.png')} alt='' />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div className='text-box'>
                                                                                {irlPlantersJSON?.[Number(reward[0]) - 1]?.['name'] &&
                                                                                    <h4>{`${irlPlantersJSON?.[Number(reward[0]) - 1]?.['name']} #${reward[0]}`}</h4>
                                                                                }
                                                                                <div className='price-area'>
                                                                                    <p>Quantity: <b>{Number(reward[1])}</b></p>
                                                                                    <Link className='buy-btn' to={`/IrlPlanters#${reward[0]}`}>View</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {userProfile?.['metadataNFT'].length == 0 && Object.values(myIrlPlanters).length == 0 && <>
                                        <h4>No items</h4>
                                        <div className='tranding-cards'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='no-item'>
                                                        <h4>No item founds</h4>
                                                        <i className="icon"><img src={require('../../static/images/not-found.png')} alt='' /></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default Profile;