import React, { useState } from "react";
import Checkbox from '@mui/material/Checkbox';
// import { loadStripe } from '@stripe/stripe-js';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
// import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

import { stripeKey, opensea } from '../../store/config';

const NftPlanters = ({ }) => {

    const [show, setShow] = React.useState();
    const toggle = () => setModal(!buyNftPlantermodal);
    const [buyNftPlantermodal, setModal] = useState(false);
    // const [stripePromise] = useState(loadStripe(stripeKey));

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                fontSize: "18px",
                color: "#32325d",
                fontSmoothing: "antialiased",
                "::placeholder": { color: "#aab7c4" },
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            }, invalid: { color: "#fa755a", iconColor: "#fa755a" }
        }
    }

    // const CheckoutForm = () => {
    //     const stripe = useStripe();
    //     const elements = useElements();
    //     const handleSubmit = async () => {}

    //     const CARD_ELEMENT_OPTIONS = {
    //         style: {
    //             base: {
    //                 fontSize: "18px",
    //                 color: "#32325d",
    //                 fontSmoothing: "antialiased",
    //                 "::placeholder": { color: "#aab7c4" },
    //                 fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    //             }, invalid: { color: "#fa755a", iconColor: "#fa755a" }
    //         }
    //     }
    //     return (
    //         <>
    //             <div className='my-4'>
    //                 <CardElement options={CARD_ELEMENT_OPTIONS} />
    //             </div>
    //             <div className="group-form style-three" >
    //                 <button disabled={!stripe || !elements} onClick={() => handleSubmit()} className="card-btn">{`Buy Now`}</button>
    //             </div>
    //         </>
    //     );
    // };

    return (
        <>
            <div className='row'>
                <div className="col">
                    <div className='tranding-box'>
                        <div className='flip-box'>
                            <div class="flip-box-front">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-11.png')} alt='' />
                                </div>
                            </div>
                            <div class="flip-box-back">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-11.png')} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='text-box'>
                            {/* {playVideo == index
                                ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                            } */}
                            <h4>Zkittlez #1</h4>
                            <div className='price-area'>
                                <p>Price: <b>0.001 ETH</b></p>
                                <button className='buy-btn' href="#">Buy</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col">
                    <div className='tranding-box'>
                        <div className='flip-box'>
                            <div class="flip-box-front">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-12.png')} alt='' />
                                </div>
                            </div>
                            <div class="flip-box-back">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-12.png')} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='text-box'>
                            {/* {playVideo == index
                                ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                            } */}
                            <h4>WhiteWidow #2</h4>
                            <div className='price-area'>
                                <p>Price: <b>0.001 ETH</b></p>
                                <button className='buy-btn' href="#">Buy</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col">
                    <div className='tranding-box'>
                        <div className='flip-box'>
                            <div class="flip-box-front">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-13.png')} alt='' />
                                </div>
                            </div>
                            <div class="flip-box-back">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-13.png')} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='text-box'>
                            {/* {playVideo == index
                                ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                            } */}
                            <h4>TrainWreck #3</h4>
                            <div className='price-area'>
                                <p>Price: <b>0.001 ETH</b></p>
                                <button className='buy-btn' href="#">Buy</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col">
                    <div className='tranding-box'>
                        <div className='flip-box'>
                            <div class="flip-box-front">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-14.png')} alt='' />
                                </div>
                            </div>
                            <div class="flip-box-back">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-14.png')} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='text-box'>
                            {/* {playVideo == index
                                ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                            } */}
                            <h4>SweetTooth #4</h4>
                            <div className='price-area'>
                                <p>Price: <b>0.001 ETH</b></p>
                                <button className='buy-btn' href="#">Buy</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col">
                    <div className='tranding-box'>
                        <div className='flip-box'>
                            <div class="flip-box-front">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-15.png')} alt='' />
                                </div>
                            </div>
                            <div class="flip-box-back">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-15.png')} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='text-box'>
                            {/* {playVideo == index
                                ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                            } */}
                            <h4>SuperSkunk #5</h4>
                            <div className='price-area'>
                                <p>Price: <b>0.001 ETH</b></p>
                                <button className='buy-btn' href="#">Buy</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col">
                    <div className='tranding-box'>
                        <div className='flip-box'>
                            <div class="flip-box-front">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-16.png')} alt='' />
                                </div>
                            </div>
                            <div class="flip-box-back">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-16.png')} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='text-box'>
                            {/* {playVideo == index
                                ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                            } */}
                            <h4>SourDiesel #6</h4>
                            <div className='price-area'>
                                <p>Price: <b>0.001 ETH</b></p>
                                <button className='buy-btn' href="#">Buy</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col">
                    <div className='tranding-box'>
                        <div className='flip-box'>
                            <div class="flip-box-front">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-17.png')} alt='' />
                                </div>
                            </div>
                            <div class="flip-box-back">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-17.png')} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='text-box'>
                            {/* {playVideo == index
                                ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                            } */}
                            <h4>PurpleHaze #7</h4>
                            <div className='price-area'>
                                <p>Price: <b>0.001 ETH</b></p>
                                <button className='buy-btn' href="#">Buy</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col">
                    <div className='tranding-box'>
                        <div className='flip-box'>
                            <div class="flip-box-front">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-18.png')} alt='' />
                                </div>
                            </div>
                            <div class="flip-box-back">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-18.png')} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='text-box'>
                            {/* {playVideo == index
                                ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                            } */}
                            <h4>NorthernLights #8</h4>
                            <div className='price-area'>
                                <p>Price: <b>0.001 ETH</b></p>
                                <button className='buy-btn' href="#">Buy</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col">
                    <div className='tranding-box'>
                        <div className='flip-box'>
                            <div class="flip-box-front">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-19.png')} alt='' />
                                </div>
                            </div>
                            <div class="flip-box-back">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-19.png')} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='text-box'>
                            {/* {playVideo == index
                                ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                            } */}
                            
                            <h4>GorillaGlue #9</h4>
                            <div className='price-area'>
                                <p>Price: <b>0.001 ETH</b></p>
                                <button className='buy-btn' href="#">Buy</button>
                            </div>
                            
                        </div>
                    </div>

                </div>
                <div className="col">
                    <div className='tranding-box'>
                        <div className='flip-box'>
                            <div class="flip-box-front">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-20.png')} alt='' />
                                </div>
                            </div>
                            <div class="flip-box-back">
                                <div className='img-box'>
                                    <img src={require('../../static/images/mystery-box-20.png')} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='text-box'>
                            {/* {playVideo == index
                                ? <button className='pause-btn' onClick={() => setPlayVideo(null)}><img clas src={require('../../static/images/pause-btn.png')} alt='' /></button>
                                : <button className='play-btn' onClick={() => setPlayVideo(index)}><img clas src={require('../../static/images/video-icon.png')} alt='' /></button>
                            } */}
                            <h4>BruceBanner #10</h4>
                            <div className='price-area'>
                                <p>Price: <b>0.001 ETH</b></p>
                                <button className='buy-btn' href="#">Buy</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
             {/* -----------------BUY NFT MODAL----------------- */}
             <Modal
                className='modal-login style-two modal-login ' isOpen={buyNftPlantermodal} toggle={toggle}
                aria-labelledby="contained-modal-title-vcenter"centered >
                <ModalHeader toggle={toggle}>NFT Planters</ModalHeader>
                    <ModalBody className='modal-body'>
                        <div className={show ? "buy-block modal-area active" : "modal-area buy-block"}>
                            <ul className="step-box">
                                <li className={show ? "" : "active"}>Selected Planter</li>
                                <li className={show ? "active" : ""}>Shippin</li>
                            </ul>
                            <div className="shipping-info">
                                <table className="table">
                                        <tbody>
                                            <tr>
                                                <td className="buy-nft">
                                                    <Checkbox checked={true} icon={<CircleUnchecked />} checkedIcon={<CircleChecked />} />
                                                </td>
                                                <td>
                                                    <div className="about-game-img">
                                                        <img src={require('../../static/images/mystery-box-11.png')} alt='' />
                                                    </div>
                                                </td>
                                                <td><label for="option1"> Zkittlez #1</label></td>
                                                <td><input name="number" type="number" /></td>
                                                <td> ETH</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="form-area">
                                        {/* <div className="group-form style-two">
                                            <span className="icon"><img src={require('../../static/images/valuted-icon.png')} alt='' /></span>
                                            <label class="switch"><input type="checkbox" /><span class="slider round"></span></label>
                                            <span className="icon style-two"><img src={require('../../static/images/shiped-nft.png')} alt='' /></span>
                                        </div> */}
                                        
                                        <p className='mt-3 bottom-text'>By purchasing this card, you acknowledge that you have read and accept our terms of services</p>
                                    </div>
                                    <div className="next-btn text-center">
                                        <button onClick={() => setShow(!show)} className="btn-style-one">Next</button>
                                    </div>
                            </div>
                            <div className="card-info allowlist-sec">
                                
                                <form>
                                <div className='row form-box'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="email"
                                                placeholder='Enter your Email'
                                                name="email"
                                                // value={email}
                                                // onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="phone"
                                                placeholder='Enter your Phone'
                                                name="phone"
                                                // value={phone}
                                                // onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='First Name'
                                                name="FName"
                                                // value={FName}
                                                // onChange={(e) => setFName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='Last Name'
                                                name="LastName"
                                                // value={LastName}
                                                // onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='Address 1'
                                                name="Address1"
                                                // value={Address1}
                                                // onChange={(e) => setAddress1(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='Address 2'
                                                name="Address2"
                                                // value={Address2}
                                                // onChange={(e) => setAddress2(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='City'
                                                name="City"
                                                // value={City}
                                                // onChange={(e) => setCity(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='State'
                                                name="State"
                                                // value={State}
                                                // onChange={(e) => setState(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="group-form">
                                            <input
                                                type="text"
                                                placeholder='Zip Code'
                                                name="ZipCode"
                                                // value={ZipCode}
                                                // onChange={(e) => setZipCode(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className='group-form'>
                                        <h6 className="text-left ml-3 mb-2">Add Card Option</h6>
                                        <Elements stripe={stripePromise}> <CheckoutForm /> </Elements>
                                    </div> */}
                                </div>
                            </form>
                            </div>
                        </div>
                    </ModalBody>
            </Modal>
        </>
    );
}

export default NftPlanters;